// FIXME merge with constants
export const KARTE_LANDING = '/karte'
export const KARTE_LEADER_HOME = '/karte/leader/home'
export const KARTE_LEADER_PREPARE = '/karte/leader/prepare'
export const KARTE_LEADER_SEARCH = '/karte/leader/search'
export const KARTE_LEADER_QUESTIONNAIRE = '/karte/leader/questionnaire/:id'
export const KARTE_LEADER_RESULTS = '/karte/leader/questionnaire/:id/results'
export const KARTE_MEMBER = '/karte/member/:leaderId'
export const KARTE_MEMBER_PREPARE = '/karte/member/:leaderId/questionnaire'
export const KARTE_MEMBER_QUESTIONNAIRE = '/karte/member/:leaderId/questionnaire/:id'
export const KARTE_MEMBER_RESULTS = '/karte/member/:leaderId/questionnaire/:id/results'

export const HINT = '/hint'
export const HINT_TIPS_HOME = '/hint/tips'
export const HINT_FAQ_HOME = '/hint/faq'
export const HINT_TIPS = '/hint/tips/:id'
export const HINT_FAQ = '/hint/faq/:id'
export const HINT_TIPS_PAGE = '/hint/tips/pages'
export const HINT_FAQ_PAGE = '/hint/faq/pages'
export const HINT_PROFILE = '/hint/tips/profile/:id'
export const HINT_PROFILE_PAGE = '/hint/tips/profile/:id/pages'
export const HINT_TIPS_SEARCH = '/hint/tips/search/category/:id'
export const HINT_FAQ_SEARCH = '/hint/faq/search/category/:id'
export const HINT_SEARCH_PAGE = '/hint/:id/search/category/:id/pages'

export const SELFCHECK = '/selfcheck'
export const SELFCHECK_LANDING = '/selfcheck/landing'
export const SELFCHECK_QUESTIONS = '/selfcheck/questions'
export const SELFCHECK_RESULTS = '/selfcheck/results'

export const PAGE_SETUP_LANDING = '/setup'
export const PAGE_SETUP_HOME = '/setup/home'
export const PAGE_SETUP_LIST = '/setup/list'
export const PAGE_SETUP_EMAILNOTIFY = '/setup/email-notify'

// ONBOARDING
export const ONBOARDING_LANDING = '/onboarding'

export const ONBOARDING_ROUTE_CATCH = '/onboarding/:teamId/*'

export const TO_ONBOARDING_TIMELINE = (teamId?: string) => {
  return `/onboarding/${teamId}/timeline`
}

export enum OnbPages {
  // Common
  ONBOARDING_PAGE_USAGE = '/onboarding/:teamId/usage',
  Timeline = '/onboarding/:teamId/timeline',
  TimelinePost = '/onboarding/:teamId/timeline/:postId',
  TimelinePostEdit = '/onboarding/:teamId/timeline/:postId/edit',
  TimelinePostLikes = '/onboarding/:teamId/timeline/:postId/likes',
  TimelineCommentLikes = '/onboarding/:teamId/timeline/:postId/comment/likes',
  TimelineSecondCommentLikes = '/onboarding/:teamId/timeline/:postId/comment/second/likes',
  Mypage = '/onboarding/:teamId/mypage',
  MypageEdit = '/onboarding/:teamId/mypage-edit',
  Team = '/onboarding/:teamId/team',
  TeamMember = '/onboarding/:teamId/teammember/:userId?',
  Notification = '/onboarding/:teamId/notification',
  NotificationDetail = '/onboarding/:teamId/notification/:notificationId',
  //common overVIew
  Ranking = '/onboarding/:teamId/ranking',
  MemberStatus = '/onboarding/:teamId/memberstatus',
  MemberStatusDetail = '/onboarding/:teamId/memberstatus/:userId',

  // Admin's and Supporter's action management and member management view
  ActionManage = '/onboarding/:teamId/action-manage',
  ActionManageDetail = '/onboarding/:teamId/actions/:userId/detail/:actionId',
  ActionManageAdd = '/onboarding/:teamId/action-add',
  ActionManageAddCSV = '/onboarding/:teamId/action-add-by-csv',
  ActionManageEdit = '/onboarding/:teamId/action-edit/:userId/target/:actionId',

  MemberManage = '/onboarding/:teamId/member-manage',
  // Admin view
  MemberManageAddMember = '/onboarding/:teamId/member-manage/add-member',
  MemberManageInviteEdit = '/onboarding/:teamId/member-manage/invite-edit/:userId',
  MemberManageAddMemberCSV = '/onboarding/:teamId/member-manage/add-member-by-csv',
  MemberManageActions = '/onboarding/:teamId/member-manage-actions/:userId',
  MemberManageActionDetail = '/onboarding/:teamId/member-manage-actions/:userId/:actionId',

  //Admin and Supporter  overVIew
  Overview = '/onboarding/:teamId/overview',

  // Member's action view
  Actions = '/onboarding/:teamId/actions',
  ActionsDetail = '/onboarding/:teamId/actions/:userId/:actionId',

  // Member, shown once per day
  DailyCheck = '/onboarding/:teamId/daily-check',
}

export const WITHDRAW = '/withdraw'

export enum Pages {
  // Tools
  TeamTools = '/tools',

  // Download documents for authenticated users
  DocumentsDownload = '/documents/dl',

  // Team
  TeamsLanding = '/teams',
  TeamsStart = '/teams-start',
  TeamsList = '/teams/:teamId/list',
  TeamsListDirect = '/teams/list/:teamId/:viewTarget',
  TeamsNew = '/teams/:teamId/new',
  TeamsNewByCsv = '/teams/:teamId/new-by-csv',
  TeamsEdit = '/teams/:teamId/edit/:editTeamId',
  TeamsSelect = '/teams/:teamId/team-select',

  TeamsDashboard = '/teams/:teamId',

  TeamsAnket = '/teams/:teamId/anket',
  TeamsAnketNew = '/teams/:teamId/anket/:anketId',

  TeamsReview = '/teams/:teamId/review/:reviewId',

  TeamsAbout = '/teams/:teamId/about',
  TeamsTools = '/teams/:teamId/tools',
  TeamsUsageMovie = '/teams/:teamId/usageMovie',
  TeamsPlan = '/teams/:teamId/plan',

  TeamsMembers = '/teams/:teamId/members',
  TeamsMembersEdit = '/teams/:teamId/members/:teamMemberId/edit',

  TeamsMembersAdd = '/teams/:teamId/members-add',
  TeamsMembersAddByCsv = '/teams/:teamId/members-add-by-csv',

  TeamsCorp = '/teams/:teamId/corp',
  TeamsPayment = '/teams/:teamId/payment',
  TeamsAdmin = '/teams/:teamId/admin',
  TeamsAdminTransfer = '/teams/:teamId/admin/transfer',
  TeamsAdminTransferAccept = '/teams/:teamId/admin/transfer/:transferToken',

  TeamsTool = '/teams/:teamId/tools',

  TeamsToolBuilding = '/teams/:teamId/tools/building',
  TeamsToolBuildingDashboard = '/teams/:teamId/tools/building/dashboard',
  TeamsToolBuildingDashboardActionCreate = '/teams/:teamId/tools/building/dashboard/action/create',
  TeamsToolBuildingDashboardActionEdit = '/teams/:teamId/tools/building/dashboard/action/:actionId/edit',
  TeamsToolBuildingDashboardActionPreview = '/teams/:teamId/tools/building/dashboard/action/:actionId/preview',
  TeamsToolBuildingDashboardActionCompleted = '/teams/:teamId/tools/building/dashboard/action/:actionId/completed',
  TeamsToolBuildingDashboardActionReset = '/teams/:teamId/tools/building/reset',
  TeamsToolBuildingSubs = '/teams/:teamId/tools/building/subs',

  TeamsToolKarte = '/teams/:teamId/tools/karte',
  TeamsToolKarteHome = '/teams/:teamId/tools/karte/home',
  TeamsToolKarteDiagnose = '/teams/:teamId/tools/karte/diagnose',
  TeamsToolKarteResult = '/teams/:teamId/tools/karte/result/:karteId',

  // Free Play
  Hataraku = '/hataraku',
  HatarakuQuestionnaire = '/hataraku/questionnaire',
  HatarakuResults = '/hataraku/results/:tendencyId/:id',
}
