import * as React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Breadcrumb } from 'components/Breadcrumb'
import { BackCard } from 'pages/contact/components/BackCard'
import { BREAKPOINT, HubSpotForm } from 'pages/contact/components/HubSpot'

import { constants } from 'assets'

export const ContactPage: React.FC = () => {
  const classes = useStyles()

  const FORM_ID = '903ea780-b10e-4d1b-84ef-4eb0b25d0e00'
  const isBreakpointsDown = useMediaQuery(useTheme().breakpoints.down(BREAKPOINT + 48))

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.topItemWrapper}>
      <div className={classes.breadcrumb}>
        <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="Cocolaboお問合せフォーム" />
      </div>
      <BackCard
        style={
          isBreakpointsDown
            ? { margin: '64px 24px 30px', padding: '0', maxWidth: BREAKPOINT }
            : { margin: '100px auto 60px', padding: '0', maxWidth: BREAKPOINT }
        }
      >
        <div className={classes.root}>
          <img
            className="__msg__container__content__header__icon"
            src={process.env.PUBLIC_URL + '/assets/svg/cocolaboLogo.svg'}
            alt={'failure'}
          />
          <div className={classes.topTextWrapper}>
            <div className={classes.TextWrapper}>
              <div>Cocoldaboの登録に関するお問合わせや使い方の不明点など、以下のフォームからお問合せください。</div>
              <div>
                よくある質問については「
                <Link to={constants.PAGE_TERMS} target="_blank" rel="noopener noreferrer">
                  ヘルプ
                </Link>
                」に記載がありますので、こちらもあわせてご確認ください。
              </div>
              <div className={classes.coution}>
                ※お問合せの内容によってはお答えできない場合や回答にお時間をいただく場合がありますの、予めご了承ください
              </div>
            </div>
            <div className={classes.formWrapper}>
              <HubSpotForm formId={FORM_ID} />
            </div>
          </div>
        </div>
      </BackCard>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      maxWidth: 1000,
      margin: '0 auto',
      padding: 40,
      [theme.breakpoints.down('sm')]: {
        padding: '40px 10px',
      },
    },
    topTextWrapper: {
      marginTop: 30,
    },
    TextWrapper: {
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    },
    formWrapper: {
      margin: '0 100px',
      [theme.breakpoints.down(900)]: {
        margin: '0 auto',
      },
      [theme.breakpoints.down('md')]: {
        margin: '0 auto',
      },
    },
    coution: {
      color: 'blue',
      marginTop: 30,
    },
    bottomItemWrapeer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    check: {
      fontWeight: 'bold',
      textAlign: 'center',
      verticalAlign: 'middle',
      margin: ' 20px 0',
    },
    description: {
      marginLeft: '3px',
      verticalAlign: 'text-bottom',
    },
    hr: {
      height: 1,
      backgroundColor: '#E3E3E3',
      border: 'none',
      color: '#E3E3E3',
      margin: '20px 0',
    },
    topItemWrapper: {
      position: 'relative',
    },
    breadcrumb: {
      position: 'absolute',
      top: 0,
      left: 24,
      marginTop: -60,
      [theme.breakpoints.up('md')]: {
        left: 'calc(7% + 24px)',
      },
    },
  }),
  { name: 'ContactPage' }
)
