import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { useCookies } from 'react-cookie'

import { NoStyleButton } from 'components/NoStyleButton'
import { useBuildHatarakuId } from 'pages/hataraku/hooks/useCreateHataraku'
import { cookieKeys } from 'pages/hataraku/pages/static'

import { constants, Pages } from 'assets'

export const Play: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const [, setCookie, removeCookie] = useCookies([cookieKeys.number, cookieKeys.data])
  const { buildHatarakuId } = useBuildHatarakuId()

  const handleStart = () => {
    const number = buildHatarakuId()
    setCookie(cookieKeys.number, number)
    removeCookie(cookieKeys.data)
    history.push(Pages.HatarakuQuestionnaire)
    window.scrollTo(0, 0)
  }

  return (
    <div className={classes.topButtonBox}>
      <NoStyleButton type="button" onClick={handleStart}>
        <span>あなたの「はたらく価値観」が見つかる！</span>
        診断スタート
      </NoStyleButton>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    topButtonBox: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 24,
      width: '100%',
      height: 64,
      background: 'rgba(246, 186, 0, 0.30)',
      [theme.breakpoints.down('sm')]: {
        height: 56,
      },
      '& button': {
        position: 'relative',
        bottom: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: 400,
        height: 92,
        background: `linear-gradient(90deg, ${constants.COLOR_BLUE_DARK2} 0%, #00C9D9 100%)`,
        boxShadow: `0px 6px 0px 0px ${constants.TEXT_GRAY_DARK}`,
        color: 'white',
        fontSize: 24,
        fontWeight: 'bold',
        borderRadius: 46,
        [theme.breakpoints.down('sm')]: {
          width: 320,
          height: 76,
          fontSize: 20,
        },
        '@media (hover: hover)': {
          '&:hover': {
            background: `linear-gradient(90deg, #51B5BD 0%, #33D4E1 100%)`,
          },
        },
        '&:active': {
          marginTop: 6,
          boxShadow: 'none',
        },
        '& span': {
          fontSize: 16,
          [theme.breakpoints.down('sm')]: {
            fontSize: 14,
          },
        },
      },
    },
  }),
  { name: 'Play' }
)
