import React, { useEffect } from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { EmblaOptionsType, EmblaPluginType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'

import { ToolCard } from 'pages/hataraku/pages/results/components'
import {
  DotButton,
  useDotButton,
} from 'pages/hataraku/pages/results/components/IntroductionCocolabo/EmblaCarouselDotButton'
import { TOOL_DATA } from 'pages/landing/components/topPageModules/data/tool.data'

import { constants } from 'assets'

type Props = {
  options?: EmblaOptionsType
  plugins?: EmblaPluginType[]
}

export const EmblaCarousel: React.FC<Props> = ({ options, plugins }) => {
  const classes = useStyles()
  const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins)
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi)

  useEffect(() => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return
  }, [emblaApi])

  return (
    <section className={classes.embla}>
      <div className={classes.emblaViewport} ref={emblaRef}>
        <div className={classes.emblaContainer}>
          {TOOL_DATA.map((tool) => (
            <div className={classes.emblaSlide} key={tool.alphabetName}>
              <ToolCard tool={tool} />
            </div>
          ))}
        </div>
      </div>

      <div className={classes.emblaDots}>
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => onDotButtonClick(index)}
            className={classNames([classes.emblaDot, index === selectedIndex ? 'active' : 'inactive'])}
          />
        ))}
      </div>
    </section>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    embla: {
      maxWidth: '100%',
      margin: 'auto',
    },

    emblaViewport: {
      overflow: 'hidden',
    },

    emblaContainer: {
      backfaceVisibility: 'hidden',
      display: 'flex',
      touchAction: 'pan-y pinch-zoom',
    },

    emblaSlide: {
      flex: '0 0 100%',
      minWidth: 0,
    },

    emblaDots: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      marginTop: 24,
      [theme.breakpoints.down('sm')]: {
        marginTop: 8,
      },
    },

    emblaDot: {
      cursor: 'pointer',
      width: 8,
      height: 8,
      borderRadius: '50%',
      padding: 0,
      '&.active': {
        backgroundColor: constants.COLOR_BLUE_DARK2,
        border: `1px solid ${constants.COLOR_BLUE_DARK2}`,
      },
      '&.inactive': {
        backgroundColor: '#fff',
        border: `1px solid ${constants.COLOR_BLUE_DARK2}`,
      },
    },
  }),
  { name: 'EmblaCarousel' }
)
