import * as constants from 'assets/constants'

export type anchorLinksType = {
  id: string
  label: string
}

export type downloadsType = {
  category: string
  title: string
  detail: string
  link: string
  imgURL: string
  hubSpotFormId: string //ここの値はハブスポットを設定している方からもらう（西田でもOK）
}

export const requierdMarkStyles = {
  content: '"必須"',
  display: 'inline-block',
  fontWeight: 'bold',
  fontSize: 9,
  color: constants.COLOR_WHITE,
  backgroundColor: constants.COLOR_ALERT,
  borderRadius: 4,
  padding: 3,
  lineHeight: 1,
}
export const selectArrowIconStyles = {
  //親要素にposition: relativeをつける
  content: '""',
  width: 10,
  height: 10,
  display: 'block',
  borderTop: `solid 2px ${constants.TEXT_GRAY_DARK}`,
  borderRight: `solid 2px ${constants.TEXT_GRAY_DARK}`,
  transform: 'rotate(135deg)',
  position: 'absolute',
  top: 17,
  right: 18,
  margin: 'auto',
  pointerEvents: 'none',
}

export const anchorLinks: Array<anchorLinksType> = [
  {
    id: 'service',
    label: 'サービス',
  },
  {
    id: 'engagement',
    label: 'エンゲージメント',
  },
  {
    id: 'newEmployeeEducation',
    label: '新人教育',
  },
  {
    id: 'teamBuilding',
    label: 'チームづくり',
  },
]

export const downloadFiles: Array<downloadsType> = [
  {
    category: anchorLinks[0].id,
    title: '3分でわかるCocolaboチームビルディング',
    detail:
      '「対話」を通じて心理的安全性を浸透させ、働きがいを持って仕事に取り組めるチームづくりをサポートする実践型ツールです。',
    link: 'service_01',
    imgURL: 'service_01',
    hubSpotFormId: '5e9401f1-e660-4916-89cc-9f0117a21728',
  },
  {
    category: anchorLinks[0].id,
    title: '管理職主導型組織開発のご提案',
    detail:
      'エンゲージメント向上を目的に、今までの組織開発よりも安価で、かつ管理職のスキルとなることで永続的に実施できるようになることがポイントです。',
    link: 'service_02',
    imgURL: 'service_02',
    hubSpotFormId: '02c5a7a9-cc46-43cb-b22d-fcd343561188',
  },
  {
    category: anchorLinks[1].id,
    title: 'この1冊でエンゲージメントが分かるエンゲージメント読本',
    detail:
      'エンゲージメントとは？この1冊でエンゲージメントについて理解することができるお勧めの一冊です。働きがいのある組織をどのように作ればよいのか悩まれている企業様にオススメです。',
    link: 'engagement_01',
    imgURL: 'engagement_01',
    hubSpotFormId: '3eb58cf7-5fc8-49b7-af84-c10913bc3891',
  },
  {
    category: anchorLinks[1].id,
    title: '実践エンゲージメント読本',
    detail:
      '待望のエンゲージメント向上読本「実践編」！エンゲージメントをどうやって向上させるか悩まれている現場管理職・経営・人事の皆様にとって今、何が必要かがわかる一冊です。',
    link: 'https://new-one.co.jp/research/book/',
    imgURL: 'engagement_02',
    hubSpotFormId: '1234567890', //dummy Data
  },
  {
    category: anchorLinks[2].id,
    title: '新入社員の離職アラートを察知するオンボーディングとは',
    detail:
      '人事部を悩ます新入社員の「びっくり転職」を防ぐ方法をご紹介しています。突然の退職の要因がわかない人や前兆を察知したい方におすすめです。',
    link: 'newcomer_education_01',
    imgURL: 'newcomer_education_01',
    hubSpotFormId: 'b57d6dc4-3c0d-49dd-bfb4-90b6995b2f88',
  },
  {
    category: anchorLinks[2].id,
    title: '新入社員の主体性を高める人材育成',
    detail:
      '新入社員の配属先での早期戦力化に課題を感じている、時代の変化にあった新入社員研修ができていないと感じている方におすすめです。',
    link: 'newcomer_education_02',
    imgURL: 'newcomer_education_02',
    hubSpotFormId: '53895844-788b-455b-a9c1-6cdfbe483121',
  },
  {
    category: anchorLinks[2].id,
    title: '新入社員が職場に馴染み、活躍するために行動するべきアクションとは？',
    detail:
      '入社してくる新入社員の変化や、時代の変化を明らかに感じており、これからの新しい時代における新入社員の育成の仕方に悩まれている企業様にオススメです。',
    link: 'newcomer_education_03',
    imgURL: 'newcomer_education_03',
    hubSpotFormId: '484250bb-f000-4e71-8f8d-3ee48eaad459',
  },
  {
    category: anchorLinks[3].id,
    title: 'チームの崩壊を防ぐたった1つの方法は？',
    detail:
      '昨今の急速に進む時代の変化において、 チームのコミュニケーション不足に対する様々な弊害を解決することができる一冊です。',
    link: 'team_making_01',
    imgURL: 'team_making_01',
    hubSpotFormId: '08a43fa2-0908-44d5-8bb5-21fae123a6c8',
  },
  {
    category: anchorLinks[3].id,
    title: 'タイプ別にみる1on1のススメ【実践編】',
    detail:
      'Cocolaboでご提供している働きがい診断「カルテ」の9つのタイプにおけるタイプ別読み解き方です。タイプ別で具体的な人物像をご紹介していると共に、タイプ別にどのように関わっていけばよいかをご紹介しています。',
    link: 'team_making_02',
    imgURL: 'team_making_02',
    hubSpotFormId: '61c36f22-9c8c-41fa-89d2-b45a035adcab',
  },
  {
    category: anchorLinks[3].id,
    title: '「カルテ」から見えた年代別・職種別の働く意向',
    detail:
      'Cocolaboでご提供している働きがい診断「カルテ」を診断された方々のデータをもとに、年代別・職種別でどのような働く意向があるのかその傾向を読み解いています。昨今のエンゲージメント向上施策においてご参考にしていただける内容です。',
    link: 'team_making_03',
    imgURL: 'team_making_03',
    hubSpotFormId: '02f03948-a8fd-48c1-9c7b-c7a0fe5a7dd4',
  },
]
