export const COLOR_MAIN = '#2CBBC6'
export const COLOR_MAIN_NEW = '#00838C'
export const COLOR_MAIN_LIGHT = '#EAF9FA'
export const COLOR_MAIN_DARK = '#2BB3BE'
export const COLOR_MAIN_SECOND = '#49BBC6'
export const COLOR_SECOND = '#FCCF00'
export const COLOR_SECOND_LIGHT = '#fff9b2'
export const COLOR_BLUE = '#2CB6FF'
export const COLOR_BLUE_LIGHT = '#80D6DD'
export const COLOR_BLUE_LIGHT2 = '#F4FCFC'
export const COLOR_BLUE_LIGHT3 = '#01C9D9'
export const COLOR_BLUE_LIGHT4 = '#00C9D9'
export const COLOR_BLUE_EXTRA_LIGHT = '#bfeaee'
export const COLOR_BLUE_EXTRA_LIGHT2 = '#E9F8F9'
export const COLOR_BLUE_EXTRA_LIGHT3 = '#F4FBFC'
export const COLOR_BLUE_DARK = '#26AAB6'
export const COLOR_BLUE_DARK2 = '#26A3AD'
export const COLOR_BLUE_DARK3 = '#25A5AF'
export const COLOR_BLUE_DARK4 = '#26A4AE'
export const COLOR_TURQUOISE = '#357895'
export const COLOR_BLACK = '#292626'
export const COLOR_WHITE = '#FFFFFF'
export const COLOR_WHITE2 = '#F9F9F9'
export const COLOR_ALERT = '#D83939'
export const COLOR_GRAY = '#F5F5F5'
export const COLOR_GRAY2 = '#9D9D9D'
export const COLOR_GRAY3 = '#9F9F9F'
export const COLOR_GRAY4 = '#D9D9D9'
export const COLOR_GRAY_LIGHT = '#EBEBEB'
export const COLOR_GRAY_LIGHT2 = '#DBDBDB'
export const COLOR_GRAY_LIGHT3 = '#F8F8F8'
export const COLOR_GRAY_LIGHT4 = '#E2E2E2'
export const COLOR_GRAY_LIGHT5 = '#EEE'
export const COLOR_GRAY_LIGHT6 = '#FBFBFB'
export const COLOR_GRAY_LIGHT7 = '#F4FCFC'
export const COLOR_GRAY_LIGHT_DARK = '#EBEBEB'
export const COLOR_GRAY_DARK = '#707070'
export const COLOR_GRAY_DARK2 = '#808080'
export const COLOR_GRAY_DARK3 = '#6C6C6C'
export const COLOR_YELLOW = '#FFCF00'
export const COLOR_YELLOW2 = '#F6BA00'
export const COLOR_YELLOW3 = '#FFCD31'
export const COLOR_YELLOW_LIGHT = '#FCFCF4'
export const COLOR_YELLOW_LIGHT2 = '#FFF9E6'
export const COLOR_OCHER = '#966D27'
export const COLOR_RED = '#F54040'
export const COLOR_RED2 = '#FF4141'
export const COLOR_RED3 = '#E8610D'
export const COLOR_RED4 = '#CE2C2C'
export const COLOR_RED_LIGHT = '#FCF4F4'
export const COLOR_GREEN = '#81E066'
export const COLOR_GREEN2 = '#56D180'
export const COLOR_GREEN_LIGHT = '#C0EFB2'
export const COLOR_GREEN_DARK = '#48C13A'
export const COLOR_GREEN_FAINT = '#F4FDFC' // alpha 5%
export const COLOR_HOT_PINK = '#FF67A7'
export const COLOR_ORANGE = '#FC9B00'
export const COLOR_ORANGE_DARK = '#FFAA55'
export const COLOR_DANGER = '#CE2C2C'
export const COLOR_ORANGE_BTN = '#E8620D'
export const COLOR_TOOLTIP_CONTENT = '#F2F9F9'
export const COLOR_LIGHT_GREEN = '#F2F9F9'
export const COLOR_LIGHT_GRAY_SP_ON = '#F4FCFC'
export const COLOR_LIGHT_GRAY_SP_OFF = '#F9F9F9'

export const COLOR_ONBOARDING_MAIN = '#00838C'
export const COLOR_ONBOARDING_WHITE = '#F9F9F9'
export const COLOR_ONBOARDING_WHITE_DARK = '#F5F5F5'
export const COLOR_ONBOARDING_GRAY = '#A2A2A2'
export const COLOR_ONBOARDING_GRAY2 = '#333333'
export const COLOR_ONBOARDING_GRAY_LIGHT = '#E9E9E9'
export const COLOR_ONBOARDING_GRAY_DARK = '#707070'
export const COLOR_ONBOARDING_ALERT = '#CE2C2C'
export const COLOR_ONBOARDING_WARNING = '#F0DB00'
export const COLOR_ONBOARDING_ERROR = '#CF2C2C'
export const COLOR_ONBOARDING_SKY_BLUE = '#F4FCFC'

export const COLOR_TEAMBUILDING_TEXT = '#333'
export const COLOR_TEAMBUILDING_PRIMARY = '#00838C'
export const COLOR_TEAMBUILDING_SKY_BLUE = '#F4FCFC'
export const COLOR_TEAMBUILDING_RED = '#CE2C2C'
export const COLOR_TEAMBUILDING_RED2 = '#C62C2C'
export const COLOR_TEAMBUILDING_YERROW = '#F0DB00'
export const COLOR_TEAMBUILDING_WHITE = '#FFFFFF'
export const COLOR_TEAMBUILDING_NEUTRAL_100 = '#F9F9F9'
export const COLOR_TEAMBUILDING_NEUTRAL_200 = '#E9E9E9'
export const COLOR_TEAMBUILDING_NEUTRAL_300 = '#F5F5F5'
export const COLOR_TEAMBUILDING_NEUTRAL_400 = '#707070'
export const COLOR_TEAMBUILDING_NEUTRAL_500 = '#A2A2A2'

export const COLOR_ACTION_CLOSE = '#FFC3C3'
export const COLOR_ACTION_CLOSE_DARK = '#F20E00'
export const COLOR_ACTION_FAILED = '#DDD1FF'
export const COLOR_ACTION_FAILED_DARK = '#704291'
export const TEXT_GRAY = '#868484'
export const TEXT_GRAY2 = '#656565'
export const TEXY_GRAY3 = '#666666'
export const TEXT_GRAY_DARK = '#333333'
export const TEXT_BLACK = '#1F1818'
export const TEXT_WHITE = '#FFFFFF'
export const COLOR_ONBOARDING_TEXT_GRAY = '#A2A2A2'

export const BORDER_GRAY = '#DDDDDD'
export const BORDER_LIGHTGRAY = '#E2E6E2'

export const HEADER_HEIGHT = 64
export const HEADER_HEIGHT_SP = 56

export const FOOTER_MINI_HEIGHT = 36

export const BREAKPOINT_EXTRA_LARGE = 1140
export const BREAKPOINT_LARGE = 960
export const BREAKPOINT_MEDIUM = 720
export const BREAKPOINT_SMALL = 540

export const GHOST_BREAKPOINT_FIXED_UI = 1176

export const KICKOFF_MAX_WIDTH = {
  sp: 600,
  pc: 800,
}

export const PAGE_TOP = '/'
export const PAGE_SIGNUP = '/signup'
export const PAGE_SIGNUP_SEND_EMAIL = '/signup/send-email'
export const PAGE_SIGNUP_CONFIRM = '/signup/confirm'
export const PAGE_SIGNUP_COMPLETE = '/signup/complete'
export const PAGE_SIGNIN = '/signin'
export const PAGE_RESTORE = '/restore'
export const PAGE_RESET_PASSWORD = '/reset_password'
export const PAGE_RESET_PASSWORD_COMPLETE = '/reset_password/complete'
export const PAGE_HELP = '/help'
export const PAGE_NOTIFICATION = '/notification'
export const PAGE_ABOUT = '/about'
export const PAGE_TERMS = '/terms'
export const PAGE_TERMS_PREVIOUS = '/terms/previous'
export const PAGE_DOCUMENTS = '/resources'
export const PAGE_DOCUMENTS_DOWNLOAD = '/resources/download/:documentId'
export const TO_DOCUMENTS_DOWNLOAD = (resourcesId: string) => `/resources/download/${resourcesId}`
export const PAGE_DOCUMENTS_COMPLETE = '/resources/completed'
export const PAGE_PRIVACY_POLICY = '/privacypolicy'
export const PAGE_COACH = '/coach'
export const PAGE_TEAM = '/tools'
export const PAGE_TEAM_OLD = '/team'
export const PAGE_PLAN = '/plan'
export const PAGE_LEGAL = '/legal'
export const PAGE_CASE = '/case'
export const PAGE_CASE_USER = '/case/:id'
export const PAGE_CASE_INTRODUCTION = '/case/introduction/:contentId'
export const PAGE_FEATURES = '/features'
export const PAGE_FEATURES_KICKOFF = '/kickoff'
export const PAGE_FEATURES_OBAKE = '/obake'
export const PAGE_FEATURES_KARTE = '/karte-feature'
export const PAGE_FEATURES_ONBOARDING = '/onboarding'
export const PAGE_RESOURCES = '/resources'
export const PAGE_CONTACT = '/contact'
export const PAGE_CONTACTFINISHED = '/contact-finished'
export const PAGE_FREETRIAL = '/freetrial'

export const ERROR_CODE_EMAIL_EXISTS = 'UsernameExistsException'

export const ONBOARDING_FONT_FAMILY =
  '"Neue Haas Grotesk", "Hiragino Kaku Gothic ProN", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'

export const TEAM_BUILDING_FONT_FAMILY = ONBOARDING_FONT_FAMILY

export const TEAM_BUILDING_PRESEN_TIME = 60

export const FONT_FAMILY_BASIC =
  '"DIN Next™ OT Pro","din-condensed", "Neue Haas Grotesk", "Hiragino Kaku Gothic ProN", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'

export const FONT_FAMILY_TOP_TITLE =
  '"Fjalla One", "Lato","Noto Sans JP", "游ゴシック Medium", "YuGothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", sans-serif'
export const FONT_FAMILY_TOP_TEXT =
  '"Lato","Noto Sans JP", "游ゴシック Medium", "YuGothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", sans-serif'

export const HATARAKU_FONT_FAMILY =
  '"Noto Sans JP", "Neue Haas Grotesk", "Hiragino Kaku Gothic ProN", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'

export const FEATURE = {
  team: { id: 'kickoff', name: 'キックオフ' },
  onb: { id: 'onboarding', name: 'オンボーディング' },
  obake: { id: 'obake', name: 'オバケ' },
  karte: { id: 'karte', name: 'カルテ' },
  setup: { id: 'setup', name: 'セットアップ' },
  selfcheck: { id: 'selfcheck', name: 'セルフチェック' },
}
