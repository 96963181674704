import * as React from 'react'

import {
  Header,
  ContentsWrapper,
  Hero,
  WhatWorkDiagnosis,
  CocolaboIntroduction,
  UsageNotes,
  ResultSearchModal,
} from '../components'

export const Hataraku: React.FC = () => {
  return (
    <>
      <Header />
      <ContentsWrapper>
        <Hero />
        <WhatWorkDiagnosis />
        <CocolaboIntroduction />
        <UsageNotes />
        <ResultSearchModal />
      </ContentsWrapper>
    </>
  )
}
