export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AWSJSON: any
  AWSTime: any
  AWSDate: any
  AWSDateTime: any
  AWSTimestamp: any
  AWSEmail: any
  AWSURL: any
  AWSPhone: any
  AWSIPAddress: any
  BigInt: any
  Double: any
}

export enum AccountType {
  Personal = 'PERSONAL',
  Company = 'COMPANY',
}

export type Addresses = {
  zipcode: Scalars['String']
  prefcode?: Maybe<Scalars['String']>
  address1?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  address3?: Maybe<Scalars['String']>
  kana1?: Maybe<Scalars['String']>
  kana2?: Maybe<Scalars['String']>
  kana3?: Maybe<Scalars['String']>
}

export type AddressInput = {
  zipcode: Scalars['String']
}

export type AdminCreateTeamInput = {
  email: Scalars['String']
  name: Scalars['String']
  paymentMethod: PaymentMethod
  statusUsage: TeamStatusUsage
  teamPlanIds?: Maybe<Array<Scalars['String']>>
  teamToolIds?: Maybe<Array<Scalars['String']>>
  userLimit?: Maybe<Scalars['Int']>
}

export type AdminUpdateTeamInput = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  paymentMethod?: Maybe<PaymentMethod>
  statusUsage?: Maybe<TeamStatusUsage>
  teamPlanIds?: Maybe<Array<Scalars['String']>>
  teamToolIds?: Maybe<Array<Scalars['String']>>
  userLimit?: Maybe<Scalars['Int']>
}

export type AdminUpdateUserInput = {
  id: Scalars['ID']
  statusAdmin?: Maybe<TeamStatusAdmin>
  onboardingUsageStatus?: Maybe<OnboardingUsageStatus>
  username?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  companyIconUrl?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  companyZipCode?: Maybe<Scalars['String']>
  companyPrefecture?: Maybe<Scalars['String']>
  companyAddress1?: Maybe<Scalars['String']>
  companyAddress2?: Maybe<Scalars['String']>
  companyPhone?: Maybe<Scalars['String']>
  companyManagerName?: Maybe<Scalars['String']>
  contractAt?: Maybe<Scalars['String']>
  onboardingContractAt?: Maybe<Scalars['String']>
  billingCode?: Maybe<Scalars['String']>
}

export enum AnketStatus {
  Empty = 'EMPTY',
  Open = 'OPEN',
  Close = 'CLOSE',
}

export enum AnketType {
  Building = 'BUILDING',
  Meeting = 'MEETING',
  Setup = 'SETUP',
}

export type ApiResponseAddress = {
  status: Scalars['Int']
  message?: Maybe<Scalars['String']>
  results?: Maybe<Array<Addresses>>
}

export type CalcTeamAnketBuildingInput = {
  teamId: Scalars['ID']
}

export enum ConditionOperation {
  Equal = 'EQUAL',
  Else = 'ELSE',
}

export type Consultation = {
  id: Scalars['ID']
  username?: Maybe<Scalars['String']>
  user?: Maybe<User>
  title: Scalars['String']
  content?: Maybe<Scalars['String']>
  contentPreview?: Maybe<Scalars['String']>
  category?: Maybe<ConsultationCategory>
  isPublished?: Maybe<Scalars['Int']>
  commentCount?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export enum ConsultationCategory {
  CreatingAHighlyEngagedTeam = 'CREATING_A_HIGHLY_ENGAGED_TEAM',
  HowToUseSevenTools = 'HOW_TO_USE_SEVEN_TOOLS',
  ManagementWorries = 'MANAGEMENT_WORRIES',
  HowToUseKarte = 'HOW_TO_USE_KARTE',
  HowToUseSetup = 'HOW_TO_USE_SETUP',
  HowToUseHint = 'HOW_TO_USE_HINT',
  KickOff = 'KICK_OFF',
  Ghost = 'GHOST',
  Reflection = 'REFLECTION',
  TbCheck = 'TB_CHECK',
  Category_1 = 'CATEGORY_1',
  Category_2 = 'CATEGORY_2',
  Category_3 = 'CATEGORY_3',
}

export type ConsultationComment = {
  id: Scalars['ID']
  consultationId: Scalars['ID']
  username: Scalars['String']
  user?: Maybe<User>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type ConsultationCommentConnection = {
  items?: Maybe<Array<Maybe<ConsultationComment>>>
  nextToken?: Maybe<Scalars['String']>
}

export type ConsultationCommentCreateInput = {
  id: Scalars['ID']
  consultationId: Scalars['ID']
  content?: Maybe<Scalars['String']>
}

export type ConsultationConnection = {
  items?: Maybe<Array<Maybe<Consultation>>>
  nextToken?: Maybe<Scalars['String']>
}

export type ConsultationCreateInput = {
  id: Scalars['ID']
  title: Scalars['String']
  content?: Maybe<Scalars['String']>
  contentPreview?: Maybe<Scalars['String']>
  category?: Maybe<ConsultationCategory>
  isPublished: Scalars['Int']
}

export type ConsultationFilter = {
  category?: Maybe<ConsultationCategory>
  username?: Maybe<Scalars['String']>
  isPublished?: Maybe<Scalars['Int']>
}

export type ConsultationUpdateInput = {
  id: Scalars['ID']
  title: Scalars['String']
  content?: Maybe<Scalars['String']>
  contentPreview?: Maybe<Scalars['String']>
  category?: Maybe<ConsultationCategory>
  isPublished: Scalars['Int']
}

export type CreateGhostReportsInput = {
  teamId: Scalars['String']
  userId: Scalars['String']
  ghostIds: Array<Maybe<Scalars['String']>>
}

export type CreateGhostResearchInput = {
  teamId: Scalars['String']
  historyId: Scalars['String']
  userId?: Scalars['String']
  type: GhostResearchType
  report: Scalars['String']
}

export type CreateGhostTeamInput = {
  masterTeamId?: Scalars['String']
}

export type CreateMasterInput = {
  type: MasterType
  sort: Scalars['Int']
  title?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  detail?: Maybe<Scalars['String']>
  timing?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  businessDescription?: Maybe<Scalars['String']>
  tools?: Maybe<Array<TeamTool>>
  limit?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['Int']>
  publicRange?: Maybe<Scalars['Int']>
  parentType?: Maybe<MasterType>
  parentId?: Maybe<Scalars['String']>
}

export type CreateOrDeleteTeamBuildingActionInput = {
  teamBuildingActionDateId?: Maybe<Scalars['ID']>
  teamBuildingActionId?: Maybe<Scalars['ID']>
  date?: Maybe<Scalars['String']>
  status?: Maybe<TeamBuildingActionDateStatus>
}

export type CreateTeamAnketBuildingInput = {
  teamId: Scalars['ID']
  summaryId: Scalars['ID']
  q1?: Maybe<Scalars['Int']>
  q2?: Maybe<Scalars['Int']>
  q3?: Maybe<Scalars['Int']>
  q4?: Maybe<Scalars['Int']>
  q5?: Maybe<Scalars['Int']>
  q6?: Maybe<Scalars['Int']>
  q7?: Maybe<Scalars['Int']>
  comment?: Maybe<Array<TeamAnketBuildingCommentInput>>
  freeComment?: Maybe<Array<TeamAnketBuildingCommentInput>>
}

export type CreateTeamAnketBuildingSummaryInput = {
  teamId: Scalars['ID']
}

export type CreateTeamBuildingActionDateInput = {
  teamBuildingActionId: Scalars['ID']
  date: Scalars['String']
  status: TeamBuildingActionDateStatus
}

export type CreateTeamBuildingActionDatesInput = {
  teamBuildingActionId: Scalars['ID']
  dates: Array<Maybe<CreateOrDeleteTeamBuildingActionInput>>
}

export type CreateTeamBuildingActionInput = {
  teamId: Scalars['ID']
  assignedTeamMemberId: Scalars['ID']
  assignedTeamMemberName: Scalars['String']
  name: Scalars['String']
  reason: Scalars['String']
  content: Scalars['String']
  timing: Scalars['String']
  editable?: Maybe<Scalars['Int']>
}

export type CreateTeamBuildingReviewInput = {
  teamId: Scalars['ID']
}

export type CreateTeamContractInput = {
  teamId: Scalars['ID']
  teamPlanId: Scalars['ID']
  paymentMethod: PaymentMethod
}

export type CreateTeamInput = {
  name: Scalars['String']
  teamPlanIds?: Maybe<Array<Scalars['String']>>
  teamToolIds?: Maybe<Array<Scalars['String']>>
}

export type CreateTeamMemberError = {
  email: Scalars['String']
  errorMessage: Scalars['String']
}

export type CreateTeamMemberInput = {
  teamId: Scalars['ID']
  email: Scalars['String']
  role: Array<TeamMemberRole>
  fullName: Scalars['String']
  username?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  invitationDateTime?: Maybe<Scalars['String']>
}

export type CreateTeamMembersInput = {
  teamId: Scalars['ID']
  members: Array<TeamMemberCreationAttributes>
  invitationDateTime?: Maybe<Scalars['String']>
}

export type CreateTeamMembersResponse = {
  items?: Maybe<Array<TeamMember>>
  errors?: Maybe<Array<CreateTeamMemberError>>
}

export type CronSummary = {
  id: Scalars['ID']
  nextToken?: Maybe<Scalars['String']>
  status: CronSummaryStatus
  tryCount: Scalars['Int']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export enum CronSummaryStatus {
  Processing = 'PROCESSING',
  Ended = 'ENDED',
}

export type DeleteAccountInput = {
  userId: Scalars['ID']
  comment?: Maybe<Scalars['String']>
}

export enum DiscountType {
  FirstMonth = 'FIRST_MONTH',
  TenPercent = 'TEN_PERCENT',
}

export type Ghost = {
  id: Scalars['String']
  fileNo: Scalars['String']
  name: Scalars['String']
  sympton: Scalars['String']
  scenes: Array<Scalars['String']>
  pro: Scalars['String']
  con: Scalars['String']
  why: Scalars['String']
  solution: Scalars['String']
  actions: Array<Scalars['String']>
  anti: Scalars['String']
  caveats: Array<Scalars['String']>
  found?: Maybe<Scalars['Int']>
}

export type GhostHistory = {
  id: Scalars['ID']
  masterTeamId: Scalars['String']
  ghostId?: Maybe<Scalars['String']>
  selectedGhostData?: Maybe<Array<SelectedGhostData>>
  createdAt: Scalars['String']
}

export type GhostHistoryConnection = {
  history?: Maybe<GhostHistory>
  research?: Maybe<GhostResearchConnection>
}

export type GhostHistoryItems = {
  items?: Maybe<Array<GhostHistory>>
  nextToken?: Maybe<Scalars['String']>
}

export enum GhostMemberManage {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
}

export enum GhostMemberRole {
  Admin = 'ADMIN',
  Leader = 'LEADER',
  Member = 'MEMBER',
}

export enum GhostProgress {
  IntroDone = 'INTRO_DONE',
  S1Started = 'S1_STARTED',
  S1SelectingPresentationOptions = 'S1_SELECTING_PRESENTATION_OPTIONS',
  S1DonePresentationOptions = 'S1_DONE_PRESENTATION_OPTIONS',
  S1DoneRandomAnswers = 'S1_DONE_RANDOM_ANSWERS',
  S2Started = 'S2_STARTED',
  S2SelectingPresentationOptions = 'S2_SELECTING_PRESENTATION_OPTIONS',
  S2DonePresentationOptions = 'S2_DONE_PRESENTATION_OPTIONS',
  S2DoneRandomAnswers = 'S2_DONE_RANDOM_ANSWERS',
  S3Started = 'S3_STARTED',
  S3SelectingPresentationOptionsReason = 'S3_SELECTING_PRESENTATION_OPTIONS_REASON',
  S3DonePresentationOptionsReason = 'S3_DONE_PRESENTATION_OPTIONS_REASON',
  S3DoneRandomAnswersReason = 'S3_DONE_RANDOM_ANSWERS_REASON',
  S3DoneAggregateCause = 'S3_DONE_AGGREGATE_CAUSE',
  S3SelectingPresentationOptions = 'S3_SELECTING_PRESENTATION_OPTIONS',
  S3DonePresentationOptions = 'S3_DONE_PRESENTATION_OPTIONS',
  S3DoneRandomAnswers = 'S3_DONE_RANDOM_ANSWERS',
  S4Started = 'S4_STARTED',
  S4SelectingPresentationOptions = 'S4_SELECTING_PRESENTATION_OPTIONS',
  S4DonePresentationOptions = 'S4_DONE_PRESENTATION_OPTIONS',
  S4DoneRandomAnswers = 'S4_DONE_RANDOM_ANSWERS',
}

export type GhostReport = {
  id: Scalars['ID']
  teamId: Scalars['ID']
  userId: Scalars['ID']
  ghostId: Scalars['ID']
  reserveId?: Maybe<Scalars['ID']>
  score: Scalars['Int']
  createdAt: Scalars['String']
  scoreVotingStatus?: Maybe<GhostReportVotingStatus>
}

export type GhostReportConnection = {
  items?: Maybe<Array<Maybe<GhostReport>>>
}

export type GhostReportInput = {
  id: Scalars['ID']
  teamId: Scalars['ID']
  userId: Scalars['ID']
  ghostId: Scalars['ID']
  reserveId?: Maybe<Scalars['ID']>
  score: Scalars['Int']
  createdAt: Scalars['String']
}

export enum GhostReportVotingStatus {
  Upvote = 'UPVOTE',
  Downvote = 'DOWNVOTE',
}

export type GhostResearch = {
  id: Scalars['ID']
  teamId: Scalars['String']
  historyId: Scalars['String']
  userId?: Scalars['ID']
  like: Scalars['Int']
  type: GhostResearchType
  report: Scalars['String']
  createdAt: Scalars['String']
}

export type GhostResearchConnection = {
  first?: Maybe<Array<GhostResearch>>
  second?: Maybe<Array<GhostResearch>>
  feeling?: Maybe<Array<GhostResearch>>
  nextAction?: Maybe<Array<GhostResearch>>
}

export enum GhostResearchType {
  First = 'FIRST',
  Second = 'SECOND',
  Feeling = 'FEELING',
  NextAction = 'NEXT_ACTION',
}

export type GhostTeam = {
  id: Scalars['ID']
  masterTeamId: Scalars['String']
  name: Scalars['String']
  startAt?: Maybe<Scalars['String']>
  endAt?: Maybe<Scalars['String']>
  discover?: Maybe<Scalars['Int']>
  selectListManage?: Maybe<Scalars['Boolean']>
  report?: Maybe<Scalars['Int']>
  reportManage: Scalars['Boolean']
  research?: Maybe<Scalars['Int']>
  researchSecond?: Maybe<Scalars['Int']>
  researchManage: Scalars['Boolean']
  researchSecondManage?: Maybe<Scalars['Boolean']>
  feelingNextActionManage?: Maybe<Scalars['Boolean']>
  like?: Maybe<Scalars['Int']>
  likeSecond?: Maybe<Scalars['Int']>
  likeManage: Scalars['Boolean']
  likeSecondManage?: Maybe<Scalars['Boolean']>
  status: GhostTeamManage
  progress?: Maybe<GhostProgress>
  timerS1Started?: Maybe<Scalars['String']>
  timerS1SelectMinute?: Maybe<Scalars['Int']>
  timerS1SelectNumber?: Maybe<Scalars['Int']>
  timerS1SelectUserIds?: Maybe<Array<Scalars['String']>>
  timerS1SelectStarted?: Maybe<Scalars['String']>
  timerS2Started?: Maybe<Scalars['String']>
  timerS2SelectMinute?: Maybe<Scalars['Int']>
  timerS2SelectNumber?: Maybe<Scalars['Int']>
  timerS2SelectUserIds?: Maybe<Array<Scalars['String']>>
  timerS2SelectStarted?: Maybe<Scalars['String']>
  timerS3Started?: Maybe<Scalars['String']>
  timerS3SelectMinuteReason?: Maybe<Scalars['Int']>
  timerS3SelectNumberReason?: Maybe<Scalars['Int']>
  timerS3SelectUserIdsReason?: Maybe<Array<Scalars['String']>>
  timerS3SelectStartedReason?: Maybe<Scalars['String']>
  timerS3Started2?: Maybe<Scalars['String']>
  timerS3SelectMinute?: Maybe<Scalars['Int']>
  timerS3SelectNumber?: Maybe<Scalars['Int']>
  timerS3SelectUserIds?: Maybe<Array<Scalars['String']>>
  timerS3SelectStarted?: Maybe<Scalars['String']>
  timerS4Started?: Maybe<Scalars['String']>
  timerS4SelectMinute?: Maybe<Scalars['Int']>
  timerS4SelectNumber?: Maybe<Scalars['Int']>
  timerS4SelectUserIds?: Maybe<Array<Scalars['String']>>
  timerS4SelectStarted?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export enum GhostTeamManage {
  Wait = 'WAIT',
  Prepare = 'PREPARE',
  Doing = 'DOING',
}

export type GhostTeamMember = {
  id: Scalars['ID']
  teamId: Scalars['ID']
  userId: Scalars['ID']
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  manager: GhostMemberManage
  role: GhostMemberRole
  page?: Maybe<Scalars['Int']>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type GhostTeamMemberConnection = {
  items: Array<GhostTeamMember>
}

export type GhostTeamMemberCreateInput = {
  teamId: Scalars['String']
  userId: Scalars['String']
  role: GhostMemberRole
  page?: Maybe<Scalars['Int']>
}

export type GhostTeamMemberUpdateInput = {
  id: Scalars['String']
  manager?: Maybe<GhostMemberManage>
  role?: Maybe<GhostMemberRole>
  page?: Maybe<Scalars['Int']>
  memberId?: Maybe<Scalars['String']>
  masterTeamId?: Maybe<Scalars['String']>
}

export type Hint = {
  id: Scalars['ID']
  username: Scalars['String']
  user?: Maybe<User>
  title: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  contentPreview?: Maybe<Scalars['String']>
  category?: Maybe<HintCategory>
  isPublished: Scalars['Int']
  commentCount?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export enum HintCategory {
  CreatingAHighlyEngagedTeam = 'CREATING_A_HIGHLY_ENGAGED_TEAM',
  HowToUseSevenTools = 'HOW_TO_USE_SEVEN_TOOLS',
  ManagementWorries = 'MANAGEMENT_WORRIES',
  HowToUseKarte = 'HOW_TO_USE_KARTE',
  HowToUseSetup = 'HOW_TO_USE_SETUP',
  HowToUseHint = 'HOW_TO_USE_HINT',
  KickOff = 'KICK_OFF',
  Ghost = 'GHOST',
  Reflection = 'REFLECTION',
  TbCheck = 'TB_CHECK',
  Category_1 = 'CATEGORY_1',
  Category_2 = 'CATEGORY_2',
  Category_3 = 'CATEGORY_3',
}

export type HintComment = {
  id: Scalars['ID']
  hintId: Scalars['ID']
  username: Scalars['String']
  user?: Maybe<User>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type HintCommentConnection = {
  items?: Maybe<Array<Maybe<HintComment>>>
  nextToken?: Maybe<Scalars['String']>
}

export type HintCommentCreateInput = {
  id: Scalars['ID']
  hintId: Scalars['ID']
  content?: Maybe<Scalars['String']>
}

export type HintConnection = {
  items?: Maybe<Array<Maybe<Hint>>>
  nextToken?: Maybe<Scalars['String']>
}

export type HintCreateInput = {
  id: Scalars['ID']
  title: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  contentPreview?: Maybe<Scalars['String']>
  category?: Maybe<HintCategory>
  isPublished: Scalars['Int']
}

export type HintFilter = {
  category?: Maybe<HintCategory>
  username?: Maybe<Scalars['String']>
  isPublished?: Maybe<Scalars['Int']>
}

export type HintUpdateInput = {
  id: Scalars['ID']
  title: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  contentPreview?: Maybe<Scalars['String']>
  category?: Maybe<HintCategory>
  isPublished: Scalars['Int']
}

export type IceBreak = {
  id: Scalars['ID']
  parentType: MasterType
  parentId: Scalars['String']
  sort: Scalars['Int']
  nameShort?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nameLong?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type IceBreakCategory = {
  id: Scalars['ID']
  sort: Scalars['Int']
  nameShort?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nameLong?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type Identity = {
  username: Scalars['String']
}

export enum IndexGetTeamBuildingActionDates {
  TeamId = 'TEAM_ID',
  AssignedTeamMemberId = 'ASSIGNED_TEAM_MEMBER_ID',
  TeamBuildingActionId = 'TEAM_BUILDING_ACTION_ID',
}

export type Invoice = {
  id: Scalars['ID']
  type: InvoiceType
  userId: Scalars['ID']
  userEmail: Scalars['String']
  userPhone: Scalars['String']
  userFullName: Scalars['String']
  username: Scalars['String']
  amount: Scalars['Int']
  cost: Scalars['Int']
  discount: Scalars['Int']
  status: InvoiceStatus
  date?: Maybe<Scalars['String']>
  dummyHash: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
  user?: Maybe<User>
}

export type InvoiceConnection = {
  items: Array<Invoice>
  nextToken?: Maybe<Scalars['String']>
}

export type InvoiceDetail = {
  id: Scalars['ID']
  invoiceId: Scalars['ID']
  teamId?: Maybe<Scalars['String']>
  teamName?: Maybe<Scalars['String']>
  method?: Maybe<PaymentMethod>
  discountType?: Maybe<DiscountType>
  cost?: Maybe<Scalars['Int']>
  discount?: Maybe<Scalars['Int']>
  plans?: Maybe<Scalars['String']>
  dummyHash: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type InvoiceDetailTeam = {
  id: Scalars['ID']
  invoiceId: Scalars['ID']
  teamId: Scalars['String']
  teamName: Scalars['String']
  method: PaymentMethod
  discountType: DiscountType
  cost: Scalars['Int']
  discount: Scalars['Int']
  plans: Scalars['String']
  dummyHash: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type InvoiceFilter = {
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export enum InvoiceOrder {
  Default = 'DEFAULT',
  Created = 'CREATED',
  Updated = 'UPDATED',
}

export enum InvoiceStatus {
  Created = 'CREATED',
  Published = 'PUBLISHED',
  Confirmed = 'CONFIRMED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export enum InvoiceType {
  Team = 'TEAM',
}

export type Karte = {
  id: Scalars['ID']
  leaderId: Scalars['ID']
  name?: Maybe<Scalars['String']>
  data?: Maybe<Scalars['String']>
  leaderNumber?: Maybe<Scalars['String']>
  leaderData?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type KarteConnection = {
  items?: Maybe<Array<Maybe<Karte>>>
  nextToken?: Maybe<Scalars['String']>
}

export type KarteFilter = {
  leaderId?: Maybe<Scalars['String']>
}

export type KarteLeaderInput = {
  id: Scalars['ID']
  name: Scalars['String']
  leaderNumber: Scalars['String']
  leaderData: Scalars['String']
  data: Scalars['String']
}

export type KarteMemberInput = {
  id: Scalars['ID']
  leaderId: Scalars['ID']
  data: Scalars['String']
}

export type KarteMemberSendInput = {
  id: Scalars['ID']
  leaderId: Scalars['ID']
  memberEmail: Scalars['String']
  sendToLeader?: Maybe<Scalars['Boolean']>
}

export type Maintain = {
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  timing?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  publicRange?: Maybe<Scalars['Int']>
}

export type Master = {
  id: Scalars['ID']
  type: MasterType
  sort: Scalars['Int']
  title?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  detail?: Maybe<Scalars['String']>
  timing?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  nameShort?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nameLong?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  businessDescription?: Maybe<Scalars['String']>
  tools?: Maybe<Array<TeamTool>>
  limit?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['Int']>
  publicRange?: Maybe<Scalars['Int']>
  parentType?: Maybe<MasterType>
  parentId?: Maybe<Scalars['String']>
}

export type MasterConnection = {
  items: Array<Master>
  nextToken?: Maybe<Scalars['String']>
}

export type MasterTeamMembersInput = {
  id: Scalars['ID']
  teamId: Scalars['ID']
  userId: Scalars['ID']
  role: Array<TeamMemberRole>
  fullName: Scalars['String']
  username?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  email: Scalars['String']
  statusUsage: TeamMemberStatusUsage
  dummyHash: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export enum MasterType {
  TeamPlan = 'TEAM_PLAN',
  TeamBuildingQ2Keyword = 'TEAM_BUILDING_Q2_KEYWORD',
  TeamBuildingQ4Keyword = 'TEAM_BUILDING_Q4_KEYWORD',
  TeamBuildingQ5Action = 'TEAM_BUILDING_Q5_ACTION',
  TeamBuildingReviewIceBreakCategory = 'TEAM_BUILDING_REVIEW_ICE_BREAK_CATEGORY',
  TeamBuildingReviewIceBreak = 'TEAM_BUILDING_REVIEW_ICE_BREAK',
  Maintain = 'MAINTAIN',
}

export type Mutation = {
  activateOnboardingTeamMember?: Maybe<OnboardingTeamMember>
  addLikeOnGhostResearch?: Maybe<GhostResearch>
  adminCreateTeam: Team
  adminDeleteTeam: Scalars['Boolean']
  adminUpdateInvoice: Invoice
  adminUpdateTeam: Team
  adminUpdateUser: User
  calcTeamAnketBuilding: TeamAnketBuildingSummary
  convertFakeUserOnboardingTeamMember?: Maybe<Scalars['Boolean']>
  convertTeamMember?: Maybe<Scalars['Boolean']>
  createConsultation?: Maybe<Consultation>
  createConsultationComment?: Maybe<ConsultationComment>
  createGhostReports?: Maybe<Array<GhostReport>>
  createGhostResearch?: Maybe<GhostResearch>
  createGhostTeam?: Maybe<GhostTeam>
  createGhostTeamMember?: Maybe<GhostTeamMember>
  createHint?: Maybe<Hint>
  createHintComment?: Maybe<HintComment>
  createMaster: Master
  createOnboardingAction?: Maybe<OnboardingAction>
  createOnboardingActionComment?: Maybe<OnboardingActionComment>
  createOnboardingActionFeedback?: Maybe<OnboardingActionFeedback>
  createOnboardingActionLikeToggle?: Maybe<OnboardingActionLike>
  createOnboardingActionsFromMaster?: Maybe<Scalars['Boolean']>
  createOnboardingAdmin?: Maybe<Scalars['Boolean']>
  createOnboardingPostAction?: Maybe<OnboardingPost>
  createOnboardingPostComment?: Maybe<OnboardingPostComment>
  createOnboardingPostCommentLikeToggle?: Maybe<OnboardingPostCommentLike>
  createOnboardingPostDaily?: Maybe<OnboardingPost>
  createOnboardingPostLikeToggle?: Maybe<OnboardingPostLike>
  createOnboardingPostPost?: Maybe<OnboardingPost>
  createOnboardingPostSecondComment?: Maybe<OnboardingPostSecondComment>
  createOnboardingPostSecondCommentLikeToggle?: Maybe<OnboardingPostSecondCommentLike>
  createOnboardingTeam?: Maybe<OnboardingTeam>
  createOnboardingTeamMember?: Maybe<OnboardingTeamMember>
  createTeam: Team
  createTeamAnketBuilding: TeamAnketBuilding
  createTeamAnketBuildingSummary: TeamAnketBuildingSummary
  createTeamBuildingAction: TeamBuildingAction
  createTeamBuildingActionDate: TeamBuildingActionDate
  createTeamBuildingActionDates?: Maybe<Scalars['Boolean']>
  createTeamBuildingReview: TeamBuildingReview
  createTeamContract: TeamContract
  createTeamMember: TeamMember
  createTeamMembers: CreateTeamMembersResponse
  deleteAccount: Scalars['Boolean']
  deleteConsultation?: Maybe<Consultation>
  deleteGhostTeam?: Maybe<Scalars['Boolean']>
  deleteGhostTeamMember?: Maybe<GhostTeamMember>
  deleteHint?: Maybe<Hint>
  deleteMaster?: Maybe<Master>
  deleteOnboardingAction?: Maybe<OnboardingAction>
  deleteOnboardingActionComment?: Maybe<OnboardingActionComment>
  deleteOnboardingActionFeedback?: Maybe<OnboardingActionFeedback>
  deleteOnboardingPostComment?: Maybe<OnboardingPostComment>
  deleteOnboardingPostPost?: Maybe<OnboardingPost>
  deleteOnboardingPostSecondComment?: Maybe<OnboardingPostSecondComment>
  deleteOnboardingTeamMember?: Maybe<OnboardingTeamMember>
  deleteSetupStartedAtListItem?: Maybe<Scalars['Boolean']>
  deleteTeam: Scalars['Boolean']
  deleteTeamBuilding?: Maybe<TeamBuilding>
  deleteTeamBuildingAction?: Maybe<TeamBuildingAction>
  deleteTeamBuildingActionDate?: Maybe<TeamBuildingActionDate>
  deleteTeamMember: Scalars['Boolean']
  forceGetVoteResult: VoteReportsResult
  isConfirmed?: Maybe<Scalars['Boolean']>
  karteLeaderStore?: Maybe<Karte>
  karteMemberSend?: Maybe<Karte>
  karteMemberStore?: Maybe<Karte>
  prepareAccount?: Maybe<User>
  reSendInvitationEmail: Scalars['Boolean']
  reSendTeamAnketBuilding: Scalars['Boolean']
  refreshTeamBuilding: TeamBuilding
  refreshTeamBuildingReview: TeamBuildingReview
  reserveGhostReports?: Maybe<Array<GhostReport>>
  resetTeamBuilding: TeamBuildingResetConnection
  sendEmailToUnansweredTeamAnketMembers: TeamAnketBuildingSendEmail
  sendAuthCompletedEmail: Scalars['Boolean']
  sendTeamAnketEmail: Scalars['Boolean']
  sendTeamKarteEmail: Scalars['Boolean']
  teamKarteMemberStore?: Maybe<TeamKarte>
  transferTeamAdmin?: Maybe<Scalars['Boolean']>
  transferTeamAdminAccept?: Maybe<Scalars['Boolean']>
  updateAccount?: Maybe<User>
  updateConsultation?: Maybe<Consultation>
  updateGhostHistory?: Maybe<GhostHistory>
  updateGhostTeam?: Maybe<GhostTeam>
  updateGhostTeamMember?: Maybe<GhostTeamMember>
  updateGhostTeamMemberByAdmin?: Maybe<GhostTeamMember>
  updateHint?: Maybe<Hint>
  updateMaster: Master
  updateOnboardingAction?: Maybe<OnboardingAction>
  updateOnboardingActionComment?: Maybe<OnboardingActionComment>
  updateOnboardingNotifycation?: Maybe<Notifycation>
  updateOnboardingPostAction?: Maybe<OnboardingPost>
  updateOnboardingPostComment?: Maybe<OnboardingPostComment>
  updateOnboardingPostPost?: Maybe<OnboardingPost>
  updateOnboardingPostSecondComment?: Maybe<OnboardingPostSecondComment>
  updateOnboardingTeam?: Maybe<OnboardingTeam>
  updateOnboardingTeamMember?: Maybe<OnboardingTeamMember>
  updateSelfCheck?: Maybe<SelfCheck>
  updateSetupAnketFeedback?: Maybe<SetupAnketFeedback>
  updateSetupItemFeedback?: Maybe<SetupItemFeedback>
  updateTeam: Team
  updateTeamAdmin: User
  updateTeamBuilding: TeamBuilding
  updateTeamBuildingAction: TeamBuildingAction
  updateTeamBuildingActionDate: TeamBuildingActionDate
  updateTeamBuildingMember: TeamBuildingMember
  updateTeamBuildingReview: TeamBuildingReview
  updateTeamBuildingReviewMember: TeamBuildingReviewMember
  updateTeamContract: TeamContract
  updateTeamMember: TeamMember
  updateTeamMembers: Array<TeamMember>
  updateTeamPlan: Team
  voteGhostReports: VoteReportsResult
}

export type MutationActivateOnboardingTeamMemberArgs = {
  input: OnboardingTeamMemberActivateInput
}

export type MutationAddLikeOnGhostResearchArgs = {
  id: Scalars['ID']
}

export type MutationAdminCreateTeamArgs = {
  input: AdminCreateTeamInput
}

export type MutationAdminDeleteTeamArgs = {
  id: Scalars['ID']
}

export type MutationAdminUpdateInvoiceArgs = {
  input: UpdateInvoiceInput
}

export type MutationAdminUpdateTeamArgs = {
  input: AdminUpdateTeamInput
}

export type MutationAdminUpdateUserArgs = {
  input: AdminUpdateUserInput
}

export type MutationCalcTeamAnketBuildingArgs = {
  input: CalcTeamAnketBuildingInput
}

export type MutationCreateConsultationArgs = {
  input: ConsultationCreateInput
}

export type MutationCreateConsultationCommentArgs = {
  input: ConsultationCommentCreateInput
}

export type MutationCreateGhostReportsArgs = {
  input: CreateGhostReportsInput
}

export type MutationCreateGhostResearchArgs = {
  input: CreateGhostResearchInput
}

export type MutationCreateGhostTeamArgs = {
  input?: Maybe<CreateGhostTeamInput>
}

export type MutationCreateGhostTeamMemberArgs = {
  input: GhostTeamMemberCreateInput
}

export type MutationCreateHintArgs = {
  input: HintCreateInput
}

export type MutationCreateHintCommentArgs = {
  input: HintCommentCreateInput
}

export type MutationCreateMasterArgs = {
  input: CreateMasterInput
}

export type MutationCreateOnboardingActionArgs = {
  input: OnboardingActionCreateInput
}

export type MutationCreateOnboardingActionCommentArgs = {
  input: OnboardingActionCommentCreateInput
}

export type MutationCreateOnboardingActionFeedbackArgs = {
  input?: Maybe<OnboardingActionFeedbackCreateInput>
}

export type MutationCreateOnboardingActionLikeToggleArgs = {
  input: OnboardingActionLikeToggleInput
}

export type MutationCreateOnboardingActionsFromMasterArgs = {
  teamId: Scalars['ID']
}

export type MutationCreateOnboardingAdminArgs = {
  input?: Maybe<OnboardingAdminCreateInput>
}

export type MutationCreateOnboardingPostActionArgs = {
  input: OnboardingPostActionCreateInput
}

export type MutationCreateOnboardingPostCommentArgs = {
  input: OnboardingPostCommentCreateInput
}

export type MutationCreateOnboardingPostCommentLikeToggleArgs = {
  input: OnboardingPostCommentLikeToggleInput
}

export type MutationCreateOnboardingPostSecondCommentArgs = {
  input: OnboardingPostSecondCommentCreateInput
}

export type MutationCreateOnboardingPostSecondCommentLikeToggleArgs = {
  input: OnboardingPostSecondCommentLikeToggleInput
}

export type MutationCreateOnboardingPostDailyArgs = {
  input: OnboardingPostDailyCreateInput
}

export type MutationCreateOnboardingPostLikeToggleArgs = {
  input: OnboardingPostLikeToggleInput
}

export type MutationCreateOnboardingPostPostArgs = {
  input: OnboardingPostPostCreateInput
}

export type MutationCreateOnboardingTeamArgs = {
  input: OnboardingTeamCreateInput
}

export type MutationCreateOnboardingTeamMemberArgs = {
  input: OnboardingTeamMemberCreateInput
}

export type MutationCreateTeamArgs = {
  input: CreateTeamInput
}

export type MutationCreateTeamAnketBuildingArgs = {
  input: CreateTeamAnketBuildingInput
}

export type MutationCreateTeamAnketBuildingSummaryArgs = {
  input: CreateTeamAnketBuildingSummaryInput
}

export type MutationCreateTeamBuildingActionArgs = {
  input: CreateTeamBuildingActionInput
}

export type MutationCreateTeamBuildingActionDateArgs = {
  input: CreateTeamBuildingActionDateInput
}

export type MutationCreateTeamBuildingActionDatesArgs = {
  input: CreateTeamBuildingActionDatesInput
}

export type MutationCreateTeamBuildingReviewArgs = {
  input: CreateTeamBuildingReviewInput
}

export type MutationCreateTeamContractArgs = {
  input: CreateTeamContractInput
}

export type MutationCreateTeamMemberArgs = {
  input: CreateTeamMemberInput
}

export type MutationCreateTeamMembersArgs = {
  input: CreateTeamMembersInput
}

export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput
}

export type MutationDeleteConsultationArgs = {
  id: Scalars['ID']
}

export type MutationDeleteGhostTeamArgs = {
  id: Scalars['ID']
}

export type MutationDeleteGhostTeamMemberArgs = {
  id: Scalars['ID']
}

export type MutationDeleteHintArgs = {
  id: Scalars['ID']
}

export type MutationDeleteMasterArgs = {
  id: Scalars['ID']
}

export type MutationDeleteOnboardingActionArgs = {
  id: Scalars['ID']
}

export type MutationDeleteOnboardingActionCommentArgs = {
  id: Scalars['ID']
}

export type MutationDeleteOnboardingActionFeedbackArgs = {
  id: Scalars['ID']
}

export type MutationDeleteOnboardingPostCommentArgs = {
  id: Scalars['ID']
}

export type MutationDeleteOnboardingPostPostArgs = {
  id: Scalars['ID']
}

export type MutationDeleteOnboardingPostSecondCommentArgs = {
  id: Scalars['ID']
}

export type MutationDeleteOnboardingTeamMemberArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationDeleteSetupStartedAtListItemArgs = {
  setupStartedAt: Scalars['String']
}

export type MutationDeleteTeamArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTeamBuildingArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTeamBuildingActionArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTeamBuildingActionDateArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTeamMemberArgs = {
  id: Scalars['ID']
}

export type MutationForceGetVoteResultArgs = {
  teamId: Scalars['String']
}

export type MutationIsConfirmedArgs = {
  email: Scalars['String']
}

export type MutationKarteLeaderStoreArgs = {
  input: KarteLeaderInput
}

export type MutationKarteMemberSendArgs = {
  input: KarteMemberSendInput
}

export type MutationKarteMemberStoreArgs = {
  input: KarteMemberInput
}

export type MutationPrepareAccountArgs = {
  input: UserPrepareAccountInput
}

export type MutationReSendInvitationEmailArgs = {
  id: Scalars['ID']
}

export type MutationReSendTeamAnketBuildingArgs = {
  id: Scalars['ID']
}

export type MutationRefreshTeamBuildingArgs = {
  input: RefreshTeamBuildingInput
}

export type MutationRefreshTeamBuildingReviewArgs = {
  id: Scalars['ID']
}

export type MutationReserveGhostReportsArgs = {
  input: ReserveGhostReportsInput
}

export type MutationResetTeamBuildingArgs = {
  id: Scalars['ID']
}

export type MutationSendEmailToUnansweredTeamAnketMembersArgs = {
  teamId: Scalars['ID']
}

export type MutationSendAuthCompletedEmailArgs = {
  email: Scalars['String']
}

export type MutationSendTeamAnketEmailArgs = {
  teamId: Scalars['ID']
}

export type MutationSendTeamKarteEmailArgs = {
  teamId: Scalars['ID']
  end: Scalars['String']
}

export type MutationTeamKarteMemberStoreArgs = {
  input: TeamKarteMemberInput
}

export type MutationTransferTeamAdminArgs = {
  input: TransferTeamAdminInput
}

export type MutationTransferTeamAdminAcceptArgs = {
  input: TransferTeamAdminAcceptInput
}

export type MutationUpdateAccountArgs = {
  input: UserUpdateInput
}

export type MutationUpdateConsultationArgs = {
  input: ConsultationUpdateInput
}

export type MutationUpdateGhostHistoryArgs = {
  input?: Maybe<UpdateGhostHistory>
}

export type MutationUpdateGhostTeamArgs = {
  input: UpdateGhostTeamInput
}

export type MutationUpdateGhostTeamMemberArgs = {
  input: GhostTeamMemberUpdateInput
}

export type MutationUpdateGhostTeamMemberByAdminArgs = {
  input: GhostTeamMemberUpdateInput
}

export type MutationUpdateHintArgs = {
  input: HintUpdateInput
}

export type MutationUpdateMasterArgs = {
  input: UpdateMasterInput
}

export type MutationUpdateOnboardingActionArgs = {
  input: OnboardingActionUpdateInput
}

export type MutationUpdateOnboardingActionCommentArgs = {
  input: OnboardingActionCommentUpdateInput
}

export type MutationUpdateOnboardingNotifycationArgs = {
  id: Scalars['ID']
}

export type MutationUpdateOnboardingPostActionArgs = {
  input: OnboardingPostActionUpdateInput
}

export type MutationUpdateOnboardingPostCommentArgs = {
  input: OnboardingPostCommentUpdateInput
}

export type MutationUpdateOnboardingPostPostArgs = {
  input: OnboardingPostPostUpdateInput
}

export type MutationUpdateOnboardingPostSecondCommentArgs = {
  input: OnboardingPostSecondCommentUpdateInput
}

export type MutationUpdateOnboardingTeamArgs = {
  input: OnboardingTeamUpdateInput
}

export type MutationUpdateOnboardingTeamMemberArgs = {
  input: OnboardingTeamMemberUpdateInput
  userId?: Maybe<Scalars['ID']>
}

export type MutationUpdateSelfCheckArgs = {
  input: SelfCheckInput
}

export type MutationUpdateSetupAnketFeedbackArgs = {
  input: SetupAnketFeedbackUpdateInput
}

export type MutationUpdateSetupItemFeedbackArgs = {
  input: SetupItemFeedbackUpdateInput
}

export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput
}

export type MutationUpdateTeamAdminArgs = {
  input: UpdateTeamAdminInput
}

export type MutationUpdateTeamBuildingArgs = {
  input: UpdateTeamBuildingInput
}

export type MutationUpdateTeamBuildingActionArgs = {
  input: UpdateTeamBuildingActionInput
}

export type MutationUpdateTeamBuildingActionDateArgs = {
  input: UpdateTeamBuildingActionDateInput
}

export type MutationUpdateTeamBuildingMemberArgs = {
  input: UpdateTeamBuildingMemberInput
}

export type MutationUpdateTeamBuildingReviewArgs = {
  input: UpdateTeamBuildingReviewInput
}

export type MutationUpdateTeamBuildingReviewMemberArgs = {
  input: UpdateTeamBuildingReviewMemberInput
}

export type MutationUpdateTeamContractArgs = {
  input: UpdateTeamContractInput
}

export type MutationUpdateTeamMemberArgs = {
  input: UpdateTeamMemberInput
}

export type MutationUpdateTeamMembersArgs = {
  input: UpdateTeamMembersInput
}

export type MutationUpdateTeamPlanArgs = {
  input: UpdateTeamPlanInput
}

export type MutationVoteGhostReportsArgs = {
  input: VoteGhostReportsInput
}

export type Notifycation = {
  id: Scalars['ID']
  actionId: Scalars['String']
  to: Scalars['String']
  from: Scalars['String']
  teamId: Scalars['String']
  type: NotifycationType
  checkedAt?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  teamMember: OnboardingTeamMember
}

export type NotifycationConnection = {
  items?: Maybe<Array<Maybe<Notifycation>>>
  nextToken?: Maybe<Scalars['String']>
}

export enum NotifycationType {
  Comment = 'COMMENT',
  Secondcomment = 'SECONDCOMMENT',
  Like = 'LIKE',
  Commentlike = 'COMMENTLIKE',
  Secondcommentlike = 'SECONDCOMMENTLIKE',
  Action = 'ACTION',
  Leader = 'LEADER',
  Supporter = 'SUPPORTER',
  Member = 'MEMBER',
  Mention = 'MENTION',
}

export type OnboardingAction = {
  id: Scalars['ID']
  teamId: Scalars['String']
  category: OnboardingActionCategory
  mission: Scalars['String']
  why: Scalars['String']
  what: Scalars['String']
  how: Scalars['String']
  period: Scalars['Int']
  deadline?: Maybe<Scalars['Int']>
  interval?: Maybe<OnboardingActionInterval>
  importance: Scalars['Int']
  point: Scalars['Float']
  isPublished: Scalars['Boolean']
  onboardingTeam?: Maybe<OnboardingTeam>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
  publishAt: Scalars['String']
  dammyHash: Scalars['String']
  commentCount?: Maybe<Scalars['Int']>
  likeCount?: Maybe<Scalars['Int']>
  isLiked?: Maybe<Scalars['Boolean']>
}

export type OnboardingActionCommentCountArgs = {
  actionOwnerId: Scalars['ID']
}

export type OnboardingActionLikeCountArgs = {
  actionOwnerId: Scalars['ID']
}

export type OnboardingActionIsLikedArgs = {
  actionOwnerId: Scalars['ID']
}

export enum OnboardingActionCategory {
  Category1 = 'CATEGORY1',
  Category2 = 'CATEGORY2',
  Category3 = 'CATEGORY3',
  Category4 = 'CATEGORY4',
  Category5 = 'CATEGORY5',
}

export type OnboardingActionComment = {
  id: Scalars['String']
  actionId: Scalars['String']
  actionOwnerId: Scalars['String']
  userId: Scalars['String']
  teamId: Scalars['String']
  text: Scalars['String']
  createdAt: Scalars['String']
  commentCount?: Maybe<Scalars['Int']>
  user?: Maybe<User>
  teamMember?: Maybe<OnboardingTeamMember>
}

export type OnboardingActionCommentConnection = {
  items?: Maybe<Array<Maybe<OnboardingActionComment>>>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingActionCommentCreateInput = {
  id: Scalars['String']
  actionId: Scalars['String']
  actionOwnerId: Scalars['String']
  teamId: Scalars['String']
  text: Scalars['String']
}

export type OnboardingActionCommentUpdateInput = {
  id: Scalars['String']
  text: Scalars['String']
}

export type OnboardingActionCreateInput = {
  id: Scalars['String']
  teamId: Scalars['String']
  category: OnboardingActionCategory
  mission: Scalars['String']
  why: Scalars['String']
  what: Scalars['String']
  how: Scalars['String']
  isPublished: Scalars['Boolean']
  period: Scalars['Int']
  deadline?: Maybe<Scalars['Int']>
  point: Scalars['Float']
  importance: Scalars['Int']
  publishAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type OnboardingActionFeedback = {
  id: Scalars['String']
  teamId: Scalars['ID']
  userId: Scalars['String']
  teamMemberId: Scalars['String']
  actionId: Scalars['ID']
  category: OnboardingActionCategory
  value: Scalars['Int']
  importance: Scalars['Int']
  settedAt: Scalars['String']
  updatedAt: Scalars['String']
  resetAt?: Maybe<Scalars['String']>
  action?: Maybe<OnboardingAction>
}

export type OnboardingActionFeedbackCreateInput = {
  teamId: Scalars['ID']
  actionId: Scalars['ID']
  category: OnboardingActionCategory
  value: Scalars['Int']
  importance: Scalars['Int']
  settedAt: Scalars['String']
}

export type OnboardingActionFeedbackUpdateInput = {
  resetAt?: Maybe<Scalars['String']>
}

export type OnboardingActionFilter = {
  category?: Maybe<Array<Maybe<OnboardingActionCategory>>>
  teamId?: Maybe<Array<Maybe<Scalars['String']>>>
}

export enum OnboardingActionInterval {
  OneTimePerMonth = 'ONE_TIME_PER_MONTH',
  OneTimePerWeek = 'ONE_TIME_PER_WEEK',
  OneTimePerTwoWeek = 'ONE_TIME_PER_TWO_WEEK',
}

export type OnboardingActionLike = {
  id: Scalars['String']
  actionId: Scalars['String']
  actionOwnerId: Scalars['String']
  actionIdActionOwnerIdHash: Scalars['String']
  userId: Scalars['String']
  createdAt: Scalars['String']
  teamMemberId: Scalars['String']
  user?: Maybe<User>
  teamMember?: Maybe<OnboardingTeamMember>
}

export type OnboardingActionLikeConnection = {
  items: Array<OnboardingActionLike>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingActionLikeToggleInput = {
  actionId: Scalars['String']
  actionOwnerId: Scalars['String']
  teamId: Scalars['String']
}

export type OnboardingActionListConnection = {
  items?: Maybe<Array<Maybe<OnboardingAction>>>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingActionUpdateInput = {
  id: Scalars['String']
  teamId?: Maybe<Scalars['String']>
  category?: Maybe<OnboardingActionCategory>
  mission?: Maybe<Scalars['String']>
  why?: Maybe<Scalars['String']>
  what?: Maybe<Scalars['String']>
  how?: Maybe<Scalars['String']>
  isPublished?: Maybe<Scalars['Boolean']>
  period?: Maybe<Scalars['Int']>
  deadline?: Maybe<Scalars['Int']>
  point?: Maybe<Scalars['Float']>
  importance?: Maybe<Scalars['Int']>
  publishAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type OnboardingAdmin = {
  email: Scalars['String']
  emailVerified?: Maybe<Scalars['String']>
  sub: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type OnboardingAdminConnection = {
  items?: Maybe<Array<Maybe<OnboardingAdmin>>>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingAdminCreateInput = {
  email?: Maybe<Scalars['String']>
  temporaryPassword?: Maybe<Scalars['String']>
}

export enum OnboardingDaily {
  GreatShade = 'GREAT_SHADE',
  BadPrivateTime = 'BAD_PRIVATE_TIME',
  BadWorkProgress = 'BAD_WORK_PROGRESS',
  BadHumanRelation = 'BAD_HUMAN_RELATION',
  BadFeeling = 'BAD_FEELING',
  BadFeelingSomewhat = 'BAD_FEELING_SOMEWHAT',
  YesterdaysMistake = 'YESTERDAYS_MISTAKE',
  NeedToRefresh = 'NEED_TO_REFRESH',
  WantToGoHome = 'WANT_TO_GO_HOME',
  Normal = 'NORMAL',
}

export type OnboardingDailyEmail = {
  id: Scalars['String']
  email: Scalars['String']
  sendAt: Scalars['String']
  sent: Scalars['Int']
  teamId: Scalars['String']
  teamTitle: Scalars['String']
  actions: Array<Scalars['String']>
  leaderImageUrl?: Maybe<Scalars['String']>
}

export type OnboardingPost = {
  id: Scalars['String']
  teamId: Scalars['ID']
  userId: Scalars['String']
  type: OnboardingPostType
  actionId?: Maybe<Scalars['ID']>
  comment?: Maybe<Scalars['String']>
  label?: Maybe<OnboardingPostLabel>
  daily?: Maybe<OnboardingDaily>
  createdAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  likeCount?: Maybe<Scalars['Int']>
  commentCount?: Maybe<Scalars['Int']>
  fileNames?: Maybe<Array<Scalars['String']>>
  rankingActions?: Maybe<Array<Maybe<OnboardingPostRanking>>>
  rankingPosts?: Maybe<Array<Maybe<OnboardingPostRanking>>>
  rankingLikes?: Maybe<Array<Maybe<OnboardingPostRanking>>>
  teamMember?: Maybe<OnboardingTeamMember>
  user?: Maybe<User>
  action?: Maybe<OnboardingAction>
  isLiked?: Maybe<Scalars['Boolean']>
  isCommented?: Maybe<Scalars['Boolean']>
}

export type OnboardingPostActionCreateInput = {
  id: Scalars['String']
  teamId: Scalars['ID']
  actionId: Scalars['ID']
  comment: Scalars['String']
  label: OnboardingPostLabel
}

export type OnboardingPostActionUpdateInput = {
  id: Scalars['String']
  comment: Scalars['String']
  label: OnboardingPostLabel
}

export type OnboardingPostActionDeleteInput = {
  id: Scalars['String']
  comment: Scalars['String']
  label: OnboardingPostLabel
}

export type OnboardingPostComment = {
  id: Scalars['String']
  postId: Scalars['String']
  teamId: Scalars['String']
  userId: Scalars['String']
  text: Scalars['String']
  createdAt: Scalars['String']
  commentCount?: Maybe<Scalars['Int']>
  likeCount?: Maybe<Scalars['Int']>
  teamMember?: Maybe<OnboardingTeamMember>
  user?: Maybe<User>
  isLiked?: Maybe<Scalars['Boolean']>
}

export type OnboardingPostCommentConnection = {
  items?: Maybe<Array<Maybe<OnboardingPostComment>>>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingPostCommentCreateInput = {
  id: Scalars['String']
  postId: Scalars['String']
  teamId: Scalars['String']
  text: Scalars['String']
  mentions?: Maybe<Array<Scalars['String']>>
}

export type OnboardingPostCommentLike = {
  id: Scalars['String']
  postId: Scalars['String']
  userId: Scalars['String']
  createdAt: Scalars['String']
  teamMemberId: Scalars['String']
  user?: Maybe<User>
  teamMember?: Maybe<OnboardingTeamMember>
}

export type OnboardingPostCommentLikeConnection = {
  items: Array<OnboardingPostCommentLike>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingPostCommentLikeToggleInput = {
  postId: Scalars['String']
  teamId: Scalars['String']
}

export type OnboardingPostCommentUpdateInput = {
  id: Scalars['String']
  text: Scalars['String']
}

export type OnboardingPostRanking = {
  teamMemberId: Scalars['ID']
  count: Scalars['Int']
}

export type OnboardingPostSecondComment = {
  id: Scalars['String']
  postCommentId: Scalars['String']
  teamId: Scalars['String']
  userId: Scalars['String']
  text: Scalars['String']
  createdAt: Scalars['String']
  likeCount?: Maybe<Scalars['Int']>
  teamMember?: Maybe<OnboardingTeamMember>
  user?: Maybe<User>
  isLiked?: Maybe<Scalars['Boolean']>
}

export type OnboardingPostSecondCommentConnection = {
  items?: Maybe<Array<Maybe<OnboardingPostSecondComment>>>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingPostSecondCommentCreateInput = {
  id: Scalars['String']
  postCommentId: Scalars['String']
  teamId: Scalars['String']
  text: Scalars['String']
  mentions?: Maybe<Array<Scalars['String']>>
}

export type OnboardingPostSecondCommentLike = {
  id: Scalars['String']
  postSecondCommentId: Scalars['String']
  userId: Scalars['String']
  createdAt: Scalars['String']
  teamMemberId: Scalars['String']
  user?: Maybe<User>
  teamMember?: Maybe<OnboardingTeamMember>
}

export type OnboardingPostSecondCommentLikeConnection = {
  items: Array<OnboardingPostSecondCommentLike>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingPostSecondCommentLikeToggleInput = {
  postSecondCommentId: Scalars['String']
  teamId: Scalars['String']
}

export type OnboardingPostSecondCommentUpdateInput = {
  id: Scalars['String']
  text: Scalars['String']
}

export type OnboardingPostConnection = {
  items?: Maybe<Array<Maybe<OnboardingPost>>>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingPostDailyCreateInput = {
  id: Scalars['String']
  teamId: Scalars['ID']
  daily: OnboardingDaily
}

export type OnboardingPostFile = {
  fileName: Scalars['String']
  mimeType: Scalars['String']
  size?: Maybe<Scalars['Int']>
  signedUrl?: Maybe<Scalars['String']>
}

export enum OnboardingPostLabel {
  Focus = 'FOCUS',
  Good = 'GOOD',
  Struggling = 'STRUGGLING',
  HelpMe = 'HELP_ME',
}

export type OnboardingPostLike = {
  id: Scalars['String']
  postId: Scalars['String']
  userId: Scalars['String']
  icon?: Maybe<Scalars['Int']>
  createdAt: Scalars['String']
  teamMemberId: Scalars['String']
  user?: Maybe<User>
  teamMember?: Maybe<OnboardingTeamMember>
}

export type OnboardingPostLikeConnection = {
  items: Array<OnboardingPostLike>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingPostLikeToggleInput = {
  postId: Scalars['String']
  teamId: Scalars['String']
  icon?: Maybe<Scalars['Int']>
}

export type OnboardingPostPostCreateInput = {
  teamId: Scalars['ID']
  comment: Scalars['String']
  type?: Maybe<OnboardingPostType>
  actionId?: Maybe<Scalars['String']>
  fileNames?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['String']>
  mentions?: Maybe<Array<Scalars['String']>>
}

export type OnboardingPostPostUpdateInput = {
  id: Scalars['String']
  comment?: Maybe<Scalars['String']>
  fileNames?: Maybe<Array<Scalars['String']>>
  mentions?: Maybe<Array<Scalars['String']>>
}

export enum OnboardingPostType {
  Action = 'ACTION',
  ActionDone = 'ACTION_DONE',
  Daily = 'DAILY',
  Post = 'POST',
  Ranking = 'RANKING',
}

export type OnboardingRadarValue = {
  category1?: Maybe<Scalars['Float']>
  category2?: Maybe<Scalars['Float']>
  category3?: Maybe<Scalars['Float']>
  category4?: Maybe<Scalars['Float']>
  category5?: Maybe<Scalars['Float']>
}

export type OnboardingRadarValues = {
  feedback?: Maybe<OnboardingRadarValue>
}

export type OnboardingSummary = {
  id?: Maybe<Scalars['ID']>
  data?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
}

export enum OnboardingSummaryFormat {
  Data = 'DATA',
  Csv = 'CSV',
}

export enum OnboardingSummaryType {
  Daily = 'DAILY',
  Action = 'ACTION',
  Post = 'POST',
}

export type OnboardingTeam = {
  id: Scalars['String']
  title: Scalars['String']
  memberCount?: Maybe<Scalars['Int']>
  managerId: Scalars['String']
  managerName: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
  dammyHash: Scalars['String']
  usageStatus?: Maybe<OnboardingUsageStatus>
}

export type OnboardingTeamCreateInput = {
  id: Scalars['String']
  title: Scalars['String']
  managerId: Scalars['String']
  managerName: Scalars['String']
}

export type OnboardingTeamFilter = {
  title?: Maybe<Scalars['String']>
  managerId?: Maybe<Scalars['String']>
}

export type OnboardingTeamListConnection = {
  items?: Maybe<Array<Maybe<OnboardingTeam>>>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingTeamMember = {
  id: Scalars['String']
  teamId: Scalars['ID']
  userId: Scalars['ID']
  role: OnboardingTeamMemberRole
  status: OnboardingTeamMemberStatus
  reserveWillSendEmailAt?: Maybe<Scalars['String']>
  readTutorialAt?: Maybe<Scalars['String']>
  dailyConfirmedAt?: Maybe<Scalars['String']>
  stoppedAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  shortDescription?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  target?: Maybe<Scalars['String']>
  targetFileNames?: Maybe<Array<Scalars['String']>>
  emailNotify?: Maybe<Scalars['Int']>
  emailNotifyHour?: Maybe<Scalars['Int']>
  emailNotifyMinute?: Maybe<Scalars['Int']>
  lastVisitAction?: Maybe<Scalars['String']>
  completeActionCount?: Maybe<Scalars['Int']>
  completeActionMostCategory?: Maybe<OnboardingActionCategory>
  team?: Maybe<OnboardingTeam>
  user?: Maybe<User>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
  dammyHash: Scalars['String']
}

export type OnboardingTeamMemberActivateInput = {
  teamId: Scalars['ID']
}

export type OnboardingTeamMemberConnection = {
  items?: Maybe<Array<Maybe<OnboardingTeamMember>>>
  nextToken?: Maybe<Scalars['String']>
}

export type OnboardingTeamMemberCreateInput = {
  teamId: Scalars['ID']
  email: Scalars['String']
  role: OnboardingTeamMemberRole
  startedAt?: Maybe<Scalars['String']>
  reserveWillSendEmailAt?: Maybe<Scalars['String']>
}

export enum OnboardingTeamMemberRole {
  Admin = 'ADMIN',
  Supporter = 'SUPPORTER',
  Member = 'MEMBER',
}

export enum OnboardingTeamMemberStatus {
  Emailreserved = 'EMAILRESERVED',
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Stopped = 'STOPPED',
  Rejected = 'REJECTED',
}

export type OnboardingTeamMemberUpdateInput = {
  teamId: Scalars['ID']
  role?: Maybe<OnboardingTeamMemberRole>
  stoppedAt?: Maybe<Scalars['String']>
  readTutorialAt?: Maybe<Scalars['String']>
  dailyConfirmedAt?: Maybe<Scalars['String']>
  status?: Maybe<OnboardingTeamMemberStatus>
  reserveWillSendEmailAt?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  shortDescription?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  target?: Maybe<Scalars['String']>
  targetFileNames?: Maybe<Array<Scalars['String']>>
  emailNotify?: Maybe<Scalars['Int']>
  emailNotifyHour?: Maybe<Scalars['Int']>
  emailNotifyMinute?: Maybe<Scalars['Int']>
  lastVisitAction?: Maybe<Scalars['String']>
}

export type OnboardingTeamUpdateInput = {
  id: Scalars['String']
  title?: Maybe<Scalars['String']>
  managerName?: Maybe<Scalars['String']>
  managerId?: Maybe<Scalars['String']>
  memberCount?: Maybe<Scalars['Int']>
  usageStatus?: Maybe<OnboardingUsageStatus>
}

export enum OnboardingUsageStatus {
  Active = 'ACTIVE',
  Break = 'BREAK',
  Stop = 'STOP',
}

export enum PaymentMethod {
  None = 'NONE',
  Card = 'CARD',
  Invoice = 'INVOICE',
}

export enum PaymentStatus {
  Pending = 'PENDING',
  Failed = 'FAILED',
  Success = 'SUCCESS',
}

export enum Permissions {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export type Point = {
  total?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
}

export type Query = {
  adminGetInvoice?: Maybe<Invoice>
  adminGetInvoiceDetails: Array<InvoiceDetail>
  adminGetInvoices: InvoiceConnection
  adminGetTeam?: Maybe<Team>
  adminGetTeams: TeamConnection
  adminGetUsers: UserConnection
  getAccount?: Maybe<User>
  getAddressByZipcode?: Maybe<Addresses>
  getConsultation?: Maybe<Consultation>
  getConsultationCommentList?: Maybe<ConsultationCommentConnection>
  getConsultationList?: Maybe<ConsultationConnection>
  getEncryptString: Scalars['String']
  getGhost: Ghost
  getGhostHistory?: Maybe<GhostHistoryConnection>
  getGhostHistoryList?: Maybe<Array<Maybe<GhostHistoryConnection>>>
  getGhostHistoriesByMaster?: Maybe<GhostHistoryItems>
  getGhostList: Array<Ghost>
  getGhostReports?: Maybe<GhostReportConnection>
  getGhostTeam?: Maybe<GhostTeam>
  getGhostTeamByMaster?: Maybe<GhostTeam>
  getGhostTeamList?: Maybe<Array<Maybe<GhostTeam>>>
  getGhostTeamMember?: Maybe<GhostTeamMember>
  getGhostTeamMemberList?: Maybe<GhostTeamMemberConnection>
  getHint?: Maybe<Hint>
  getHintCommentList?: Maybe<HintCommentConnection>
  getHintList?: Maybe<HintConnection>
  getKarte?: Maybe<Karte>
  getKarteLeader?: Maybe<Karte>
  getKarteList?: Maybe<KarteConnection>
  getMaster?: Maybe<Master>
  getMasters?: Maybe<MasterConnection>
  getOnboardingAction?: Maybe<OnboardingAction>
  getOnboardingActionCommentList?: Maybe<OnboardingActionCommentConnection>
  getOnboardingActionLikeList?: Maybe<OnboardingActionLikeConnection>
  getOnboardingActionLikeListOfOneUser?: Maybe<OnboardingActionLikeConnection>
  getOnboardingActionList?: Maybe<OnboardingActionListConnection>
  getOnboardingActions: Array<OnboardingAction>
  getOnboardingActionsFeedbacks: Array<OnboardingActionFeedback>
  getOnboardingActionsRadar?: Maybe<OnboardingRadarValues>
  getOnboardingAdminList?: Maybe<OnboardingAdminConnection>
  getOnboardingNotifycation?: Maybe<Notifycation>
  getOnboardingNotifycationList?: Maybe<NotifycationConnection>
  getOnboardingOwnTeamMemberList?: Maybe<OnboardingTeamMemberConnection>
  getOnboardingPost?: Maybe<OnboardingPost>
  getOnboardingPostCommentLikeList?: Maybe<OnboardingPostCommentLikeConnection>
  getOnboardingPostCommentLikeListOfOneUser?: Maybe<OnboardingPostCommentLikeConnection>
  getOnboardingPostCommentList?: Maybe<OnboardingPostCommentConnection>
  getOnboardingPostFile?: Maybe<OnboardingPostFile>
  getOnboardingPostLikeList?: Maybe<OnboardingPostLikeConnection>
  getOnboardingPostLikeListOfOneUser?: Maybe<OnboardingPostLikeConnection>
  getOnboardingPostList?: Maybe<OnboardingPostConnection>
  getOnboardingPostListOfOneUser?: Maybe<OnboardingPostConnection>
  getOnboardingPostSecondCommentLikeList?: Maybe<OnboardingPostSecondCommentLikeConnection>
  getOnboardingPostSecondCommentLikeListOfOneUser?: Maybe<OnboardingPostSecondCommentLikeConnection>
  getOnboardingPostSecondCommentList?: Maybe<OnboardingPostSecondCommentConnection>
  getOnboardingSummary: OnboardingSummary
  getOnboardingTeam?: Maybe<OnboardingTeam>
  getOnboardingTeamList?: Maybe<OnboardingTeamListConnection>
  getOnboardingTeamMember?: Maybe<OnboardingTeamMember>
  getOnboardingTeamMemberList?: Maybe<OnboardingTeamMemberConnection>
  getOnboardingTeamMemberTargetFile?: Maybe<OnboardingPostFile>
  getPoints?: Maybe<Point>
  getRadar?: Maybe<Radar>
  getSelfCheck?: Maybe<SelfCheck>
  getSelfCheckCommentList?: Maybe<SelfCheckCommentConnection>
  getSetupAnketFeedback?: Maybe<SetupAnketFeedback>
  getSetupAnketFeedbacks?: Maybe<Array<Maybe<SetupAnketFeedback>>>
  getSetupItemFeedback?: Maybe<SetupItemFeedback>
  getSetupItemFeedbacks?: Maybe<Array<Maybe<SetupItemFeedback>>>
  getSetupItems?: Maybe<Array<Maybe<SetupItem>>>
  getTeam?: Maybe<Team>
  getTeamAnketBuildingSummaryListByTeam: TeamAnketBuildingItem
  getTeamAnketBuildingDataForExport?: Maybe<Array<TeamAnketBuildingDataForExport>>
  getTeamAnketBuildingStatusForStart: TeamAnketBuildingStatusForStart
  getTeamBuilding: TeamBuilding
  getTeamBuildingAction: TeamBuildingAction
  getTeamBuildingActionDate: TeamBuildingActionDate
  getTeamBuildingActionDates: TeamBuildingActionDateConnection
  getTeamBuildingActions: TeamBuildingActionConnection
  getTeamBuildingMember: TeamBuildingMember
  getTeamBuildingMembers: TeamBuildingMemberConnection
  getTeamBuildingReview: TeamBuildingReview
  getTeamBuildingReviewLatest?: Maybe<TeamBuildingReview>
  getTeamBuildingReviewMember: TeamBuildingReviewMember
  getTeamBuildingReviewMembers: TeamBuildingReviewMemberConnection
  getTeamBuildingReviewSummary: TeamBuildingReviewSummary
  getTeamBuildingReviews: TeamBuildingReviewConnection
  getTeamContracts: TeamContractConnection
  getTeamKarte?: Maybe<TeamKarte>
  getTeamKarteList: TeamKarteConnection
  getTeamMember: TeamMember
  getTeamMembers: TeamMemberConnection
  getTeamSummaryBuilding: TeamSummaryBuilding
  getTeamSummaryGhosts: Array<Ghost>
  getTeamToolUsageStatus: TeamToolUsageStatus
  getTeams: TeamConnection
  getTeamsManagedByAdmin: Array<Maybe<Team>>
  getUser?: Maybe<User>
  isKarteMembershipValid?: Maybe<Scalars['Boolean']>
  listOnboardingPostFiles: Array<Maybe<OnboardingPostFile>>
}

export type QueryAdminGetInvoiceArgs = {
  id: Scalars['ID']
}

export type QueryAdminGetInvoiceDetailsArgs = {
  invoiceId: Scalars['ID']
}

export type QueryAdminGetInvoicesArgs = {
  filter?: Maybe<InvoiceFilter>
  order?: Maybe<InvoiceOrder>
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryAdminGetTeamArgs = {
  id: Scalars['ID']
}

export type QueryAdminGetTeamsArgs = {
  filter?: Maybe<TeamFilter>
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryAdminGetUsersArgs = {
  filter?: Maybe<UserFilter>
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetAddressByZipcodeArgs = {
  input: AddressInput
}

export type QueryGetConsultationArgs = {
  id: Scalars['ID']
}

export type QueryGetConsultationCommentListArgs = {
  consultationId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetConsultationListArgs = {
  filter?: Maybe<ConsultationFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetEncryptStringArgs = {
  encrypt: Scalars['Boolean']
  text: Scalars['String']
}

export type QueryGetGhostArgs = {
  id: Scalars['ID']
}

export type QueryGetGhostHistoriesByMasterArgs = {
  masterTeamId: Scalars['ID']
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetGhostHistoryArgs = {
  id: Scalars['ID']
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetGhostHistoryListArgs = {
  masterTeamId: Scalars['ID']
  ghostId?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetGhostListArgs = {
  masterTeamId?: Maybe<Scalars['ID']>
}

export type QueryGetGhostReportsArgs = {
  teamId: Scalars['ID']
}

export type QueryGetGhostTeamArgs = {
  id: Scalars['ID']
}

export type QueryGetGhostTeamByMasterArgs = {
  masterTeamId?: Scalars['String']
}

export type QueryGetGhostTeamMemberArgs = {
  id: Scalars['ID']
}

export type QueryGetGhostTeamMemberListArgs = {
  teamId: Scalars['ID']
}

export type QueryGetHintArgs = {
  id: Scalars['ID']
}

export type QueryGetHintCommentListArgs = {
  hintId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetHintListArgs = {
  filter?: Maybe<HintFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetKarteArgs = {
  id: Scalars['ID']
  leaderId?: Maybe<Scalars['ID']>
}

export type QueryGetKarteLeaderArgs = {
  leaderNumber: Scalars['String']
}

export type QueryGetKarteListArgs = {
  filter?: Maybe<KarteFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetMasterArgs = {
  id: Scalars['ID']
}

export type QueryGetMastersArgs = {
  type: MasterType
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingActionArgs = {
  id: Scalars['ID']
}

export type QueryGetOnboardingActionCommentListArgs = {
  actionId: Scalars['ID']
  actionOwnerId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingActionLikeListArgs = {
  actionIdActionOwnerIdHash: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingActionLikeListOfOneUserArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingActionListArgs = {
  filter?: Maybe<OnboardingActionFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  teamId?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingActionsArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type QueryGetOnboardingActionsFeedbacksArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type QueryGetOnboardingActionsRadarArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type QueryGetOnboardingAdminListArgs = {
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingNotifycationArgs = {
  id: Scalars['ID']
}

export type QueryGetOnboardingNotifycationListArgs = {
  to: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingOwnTeamMemberListArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostArgs = {
  id: Scalars['ID']
}

export type QueryGetOnboardingPostCommentLikeListArgs = {
  postId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostCommentLikeListOfOneUserArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostCommentListArgs = {
  postId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostFileArgs = {
  fileName: Scalars['String']
  teamId: Scalars['ID']
}

export type QueryGetOnboardingPostLikeListArgs = {
  postId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostCommentListOfOneUserArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostLikeListOfOneUserArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}
export type QueryGetOnboardingPostSecondCommentListOfOneUserArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostListArgs = {
  teamId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostListOfOneUserArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostSecondCommentLikeListArgs = {
  postSecondCommentId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostSecondCommentLikeListOfOneUserArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingPostSecondCommentListArgs = {
  postCommentId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingSummaryArgs = {
  teamId: Scalars['ID']
  type: OnboardingSummaryType
  from?: Maybe<Scalars['String']>
  to?: Maybe<Scalars['String']>
  excludes?: Maybe<Array<Scalars['String']>>
  format?: Maybe<OnboardingSummaryFormat>
  refresh?: Maybe<Scalars['Boolean']>
}

export type QueryGetOnboardingTeamArgs = {
  id: Scalars['ID']
}

export type QueryGetOnboardingTeamListArgs = {
  filter?: Maybe<OnboardingTeamFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingTeamMemberArgs = {
  teamId: Scalars['ID']
  userId?: Maybe<Scalars['ID']>
}

export type QueryGetOnboardingTeamMemberListArgs = {
  teamId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetOnboardingTeamMemberTargetFileArgs = {
  fileName: Scalars['String']
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type QueryGetPointsArgs = {
  week: Scalars['Int']
  setupStartedAt: Scalars['String']
}

export type QueryGetRadarArgs = {
  week: Scalars['Int']
  setupStartedAt: Scalars['String']
}

export type QueryGetSelfCheckArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryGetSelfCheckCommentListArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetSetupAnketFeedbackArgs = {
  weekDate: Scalars['String']
  setupStartedAt?: Maybe<Scalars['String']>
}

export type QueryGetSetupAnketFeedbacksArgs = {
  setupStartedAt: Scalars['String']
}

export type QueryGetSetupItemFeedbackArgs = {
  id: Scalars['String']
}

export type QueryGetSetupItemFeedbacksArgs = {
  setupStartedAt: Scalars['String']
}

export type QueryGetSetupItemsArgs = {
  setupStartedAt: Scalars['String']
  category?: Maybe<SetupItemCategory>
}

export type QueryGetTeamArgs = {
  id: Scalars['ID']
}

export type QueryGetTeamAnketBuildingDataForExportArgs = {
  teamIds: Array<Scalars['ID']>
}

export type QueryGetTeamAnketBuildingStatusForStartArgs = {
  teamId: Scalars['ID']
}

export type QueryGetTeamAnketBuildingSummaryListByTeamArgs = {
  teamId: Scalars['ID']
  maxLen?: Maybe<Scalars['Int']>
}

export type QueryGetTeamBuildingArgs = {
  id: Scalars['ID']
}

export type QueryGetTeamBuildingActionArgs = {
  id: Scalars['ID']
}

export type QueryGetTeamBuildingActionDateArgs = {
  id: Scalars['ID']
}

export type QueryGetTeamBuildingActionDatesArgs = {
  id: Scalars['ID']
  index: IndexGetTeamBuildingActionDates
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
}

export type QueryGetTeamBuildingActionsArgs = {
  teamId: Scalars['ID']
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetTeamBuildingMemberArgs = {
  id: Scalars['ID']
}

export type QueryGetTeamBuildingMembersArgs = {
  teamId: Scalars['ID']
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetTeamBuildingReviewArgs = {
  id: Scalars['ID']
}

export type QueryGetTeamBuildingReviewLatestArgs = {
  teamId: Scalars['ID']
}

export type QueryGetTeamBuildingReviewMemberArgs = {
  id: Scalars['ID']
}

export type QueryGetTeamBuildingReviewMembersArgs = {
  teamBuildingReviewId: Scalars['ID']
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetTeamBuildingReviewSummaryArgs = {
  teamId: Scalars['ID']
}

export type QueryGetTeamBuildingReviewsArgs = {
  teamId: Scalars['ID']
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetTeamContractsArgs = {
  teamId: Scalars['ID']
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetTeamKarteArgs = {
  id: Scalars['ID']
}

export type QueryGetTeamKarteListArgs = {
  filter?: Maybe<TeamKarteFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type QueryGetTeamMemberArgs = {
  id: Scalars['ID']
}

export type QueryGetTeamMembersArgs = {
  teamId: Scalars['ID']
  filter?: Maybe<TeamMemberFilter>
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
}

export type QueryGetTeamSummaryBuildingArgs = {
  teamId: Scalars['ID']
}

export type QueryGetTeamSummaryGhostsArgs = {
  masterTeamId: Scalars['ID']
  limit?: Maybe<Scalars['Int']>
}

export type QueryGetTeamToolUsageStatusArgs = {
  teamTool: TeamTool
}

export type QueryGetTeamsArgs = {
  limit?: Maybe<Scalars['Int']>
  nextToken?: Maybe<Scalars['String']>
  isSortByCreatedAt?: Maybe<Scalars['Boolean']>
}

export type QueryGetUserArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryIsKarteMembershipValidArgs = {
  id: Scalars['ID']
}

export type QueryListOnboardingPostFilesArgs = {
  teamId: Scalars['ID']
  postId?: Maybe<Scalars['ID']>
}

export type QuitReasonComment = {
  id: Scalars['ID']
  username: Scalars['String']
  nickname: Scalars['String']
  email: Scalars['String']
  comment?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
}

export type Radar = {
  reaction?: Maybe<RadarValues>
  anket?: Maybe<RadarValues>
}

export type RadarValues = {
  boss?: Maybe<Scalars['Float']>
  vision?: Maybe<Scalars['Float']>
  member?: Maybe<Scalars['Float']>
  team?: Maybe<Scalars['Float']>
  other?: Maybe<Scalars['Float']>
}

export type RefreshTeamBuildingInput = {
  id: Scalars['ID']
}

export type RelatedTeamPlan = {
  id: Scalars['String']
  status: RelatedTeamPlanStatus
  name: Scalars['String']
  price: Scalars['Int']
}

export enum RelatedTeamPlanStatus {
  Enable = 'ENABLE',
  Disable = 'DISABLE',
}

export type ReserveGhostReportsInput = {
  teamId: Scalars['String']
  userId: Scalars['String']
}

export type ResultQ3RankingItem = {
  id: Scalars['String']
  answer: Scalars['String']
  count: Scalars['Int']
  people: Array<Maybe<ResultQ3RankingItemPerson>>
}

export type ResultQ3RankingItemPerson = {
  userId: Scalars['String']
  fullName: Scalars['String']
}

export type SelectOption = {
  value: Scalars['String']
  label: Scalars['String']
}

export type SelectedGhostData = {
  ghostIds: Array<Scalars['Int']>
  teamMemberId: Scalars['String']
}

export type SelfCheck = {
  id: Scalars['ID']
  data?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
  user?: Maybe<User>
}

export type SelfCheckComment = {
  id: Scalars['ID']
  head: Scalars['String']
  titleGoodFirst: Scalars['String']
  titleGoodSecond: Scalars['String']
  messageGood: Scalars['String']
  titleBad: Scalars['String']
  messageBad: Scalars['String']
  adviceFirst: Scalars['String']
  adviceSecond: Scalars['String']
  urlFirst?: Maybe<Scalars['String']>
  urlSecond?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
  dammyHash: Scalars['String']
}

export type SelfCheckCommentConnection = {
  items?: Maybe<Array<Maybe<SelfCheckComment>>>
  nextToken?: Maybe<Scalars['String']>
}

export type SelfCheckInput = {
  data?: Maybe<Scalars['String']>
}

export enum SendEmailTemplate {
  AuthCompleted = 'AUTH_COMPLETED',
  TeamMemberJoinATeam = 'TEAM_MEMBER_JOIN_A_TEAM',
  TeamMemberJoinATeamRe = 'TEAM_MEMBER_JOIN_A_TEAM_RE',
  TeamMemberJoinATeamForNew = 'TEAM_MEMBER_JOIN_A_TEAM_FOR_NEW',
  JoinATeamByAdmin = 'JOIN_A_TEAM_BY_ADMIN',
  TransferTeamAdmin = 'TRANSFER_TEAM_ADMIN',
  TransferTeamAdminAccepted = 'TRANSFER_TEAM_ADMIN_ACCEPTED',
  RequestTeamAnketBuilding = 'REQUEST_TEAM_ANKET_BUILDING',
  RequestTeamAnketBuildingRe = 'REQUEST_TEAM_ANKET_BUILDING_RE',
}

export type SesQueue = {
  id: Scalars['ID']
  type: SesQueueType
  status: SesQueueStatus
  Destination?: Maybe<SesQueueDestination>
  Message?: Maybe<SesQueueMessage>
  Source?: Maybe<Scalars['String']>
  ReplyToAddresses?: Maybe<Array<Scalars['String']>>
  sendAt?: Maybe<Scalars['String']>
  tryCount?: Maybe<Scalars['Int']>
  error?: Maybe<Scalars['String']>
  deletedReason?: Maybe<Scalars['String']>
  targetUserId?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type SesQueueDestination = {
  ToAddresses?: Maybe<Array<Scalars['String']>>
  CcAddresses?: Maybe<Array<Scalars['String']>>
  BccAddresses?: Maybe<Array<Scalars['String']>>
}

export type SesQueueMessage = {
  Subject?: Maybe<SesQueueMessageData>
  Body?: Maybe<SesQueueMessageData>
}

export type SesQueueMessageData = {
  Charset?: Maybe<Scalars['String']>
  Data: Scalars['String']
}

export enum SesQueueStatus {
  EmailReady = 'EMAIL_READY',
  EmailDone = 'EMAIL_DONE',
  EmailError = 'EMAIL_ERROR',
  EmailDeleted = 'EMAIL_DELETED',
  PushReady = 'PUSH_READY',
  PushDone = 'PUSH_DONE',
  PushError = 'PUSH_ERROR',
  PushDeleted = 'PUSH_DELETED',
}

export enum SesQueueType {
  Email = 'EMAIL',
  Push = 'PUSH',
}

export type SetupAnketFeedback = {
  id?: Maybe<Scalars['String']>
  username: Scalars['String']
  weekDate: Scalars['String']
  total: Scalars['Float']
  boss: Scalars['Float']
  vision: Scalars['Float']
  member: Scalars['Float']
  team: Scalars['Float']
  other: Scalars['Float']
  comment?: Maybe<Scalars['String']>
  setupStartedAt?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type SetupAnketFeedbackUpdateInput = {
  weekDate: Scalars['String']
  boss: Scalars['Float']
  vision: Scalars['Float']
  member: Scalars['Float']
  team: Scalars['Float']
  other: Scalars['Float']
  comment?: Maybe<Scalars['String']>
  setupStartedAt?: Maybe<Scalars['String']>
}

export type SetupItem = {
  id: Scalars['ID']
  category: SetupItemCategory
  mission: Scalars['String']
  why: Scalars['String']
  what: Scalars['String']
  how: Scalars['String']
  period: Scalars['Int']
  type?: Maybe<Scalars['String']>
  typeTitle?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  interval?: Maybe<SetupItemInterval>
  importance: Scalars['Int']
  point: Scalars['Float']
  referenceTime?: Maybe<Scalars['Float']>
}

export enum SetupItemCategory {
  Boss = 'BOSS',
  Vision = 'VISION',
  Member = 'MEMBER',
  Team = 'TEAM',
  Other = 'OTHER',
}

export type SetupItemFeedback = {
  id: Scalars['String']
  username: Scalars['String']
  setupItemId: Scalars['ID']
  category: SetupItemCategory
  value: Scalars['Int']
  point: Scalars['Float']
  comment?: Maybe<Scalars['String']>
  settedAt: Scalars['String']
  updatedAt: Scalars['String']
  setupStartedAt?: Maybe<Scalars['String']>
  setupItem?: Maybe<SetupItem>
}

export type SetupItemFeedbackUpdateInput = {
  id: Scalars['String']
  setupItemId: Scalars['ID']
  category: SetupItemCategory
  value: Scalars['Int']
  point: Scalars['Float']
  comment?: Maybe<Scalars['String']>
  settedAt: Scalars['String']
  setupStartedAt?: Maybe<Scalars['String']>
}

export enum SetupItemInterval {
  OneTimePerMonth = 'ONE_TIME_PER_MONTH',
  OneTimePerWeek = 'ONE_TIME_PER_WEEK',
  OneTimePerTwoWeek = 'ONE_TIME_PER_TWO_WEEK',
}

export enum StatusBarCommentIcon {
  Success = 'SUCCESS',
}

export type Subscription = {
  onCreateGhostResearch?: Maybe<GhostResearch>
  onCreateGhostTeamMember?: Maybe<GhostTeamMember>
  onDeleteGhostTeam?: Maybe<Scalars['Boolean']>
  onDeleteGhostTeamMember?: Maybe<GhostTeamMember>
  onForceGetVoteResult?: Maybe<VoteReportsResult>
  onUpdateGhostTeam?: Maybe<GhostTeam>
  onUpdateGhostTeamMember?: Maybe<GhostTeamMember>
  onVoteGhostReports?: Maybe<VoteReportsResult>
  updatedTeamBuilding?: Maybe<TeamBuilding>
  updatedTeamBuildingMember?: Maybe<TeamBuildingMember>
  updatedTeamBuildingReview?: Maybe<TeamBuildingReview>
  updatedTeamBuildingReviewMember?: Maybe<TeamBuildingReviewMember>
}

export type SubscriptionOnCreateGhostResearchArgs = {
  teamId: Scalars['String']
}

export type SubscriptionOnCreateGhostTeamMemberArgs = {
  teamId: Scalars['ID']
}

export type SubscriptionOnDeleteGhostTeamArgs = {
  id: Scalars['ID']
}

export type SubscriptionOnDeleteGhostTeamMemberArgs = {
  teamId: Scalars['ID']
}

export type SubscriptionOnForceGetVoteResultArgs = {
  teamId: Scalars['ID']
}

export type SubscriptionOnUpdateGhostTeamArgs = {
  id: Scalars['ID']
}

export type SubscriptionOnUpdateGhostTeamMemberArgs = {
  teamId: Scalars['ID']
}

export type SubscriptionOnVoteGhostReportsArgs = {
  teamId: Scalars['ID']
}

export type Summary = {
  id: Scalars['ID']
  data?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
}

export enum TableConst {
  Dummy = 'DUMMY',
}

export type Team = {
  id: Scalars['ID']
  ownerUserId: Scalars['ID']
  ownerUsername?: Maybe<Scalars['String']>
  ownerFullName?: Maybe<Scalars['String']>
  ownerEmail?: Maybe<Scalars['String']>
  statusAdmin: TeamStatusAdmin
  userLimit?: Maybe<Scalars['Int']>
  name: Scalars['String']
  leaderUserIds: Array<Scalars['String']>
  leaderFullNames?: Maybe<Array<Scalars['String']>>
  paymentMethod?: Maybe<PaymentMethod>
  paymentLast4Digits?: Maybe<Scalars['String']>
  paymentToken?: Maybe<Scalars['String']>
  cost: Scalars['Int']
  teamContractId?: Maybe<Scalars['ID']>
  countMember: Scalars['Int']
  countInvitedMember?: Maybe<Scalars['Int']>
  countReservedMember?: Maybe<Scalars['Int']>
  memberInvitationDateTimes?: Maybe<Array<Scalars['String']>>
  teamTools: Array<TeamTool>
  plans: Scalars['String']
  statusUsage: TeamStatusUsage
  payStartAt?: Maybe<Scalars['String']>
  payCount?: Maybe<Scalars['Int']>
  statusBarComment?: Maybe<Scalars['String']>
  statusBarCommentIcon?: Maybe<StatusBarCommentIcon>
  transferUserId?: Maybe<Scalars['String']>
  transferToken?: Maybe<Scalars['String']>
  teamBuildingStartDate?: Maybe<Scalars['String']>
  teamBuildingProgress?: Maybe<Scalars['Float']>
  teamBuildingReviewHistoryCount?: Maybe<Scalars['Int']>
  karteCount?: Maybe<Scalars['Int']>
  anketBuildingAverage?: Maybe<Scalars['Float']>
  anketBuildingGrowth?: Maybe<Scalars['Float']>
  anketBuildingValue?: Maybe<Scalars['Float']>
  anketBuildingDiff?: Maybe<Scalars['Float']>
  anketBuildingDate?: Maybe<Scalars['String']>
  anketBuildingUpdatedAt?: Maybe<Scalars['String']>
  ghostHistoryCount?: Maybe<Scalars['Int']>
  ghostLastPlayedDate?: Maybe<Scalars['String']>
  ghostLastDiscoveredId?: Maybe<Scalars['String']>
  countGhostHistory?: Maybe<Scalars['Int']>
  nextTeamBuildingReviewAt?: Maybe<Scalars['String']>
  dummyHash: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
  teamBuilding?: Maybe<TeamBuilding>
  statusViewTbCheckRespondents?: Maybe<Permissions>
}

export enum TeamAnketAnswerStatus {
  Answered = 'ANSWERED',
  Unanswered = 'UNANSWERED',
}

export type TeamAnketBuilding = {
  id: Scalars['ID']
  type: AnketType
  teamId: Scalars['ID']
  userId: Scalars['ID']
  role: Array<TeamMemberRole>
  date?: Maybe<Scalars['String']>
  q1?: Maybe<Scalars['Int']>
  q2?: Maybe<Scalars['Int']>
  q3?: Maybe<Scalars['Int']>
  q4?: Maybe<Scalars['Int']>
  q5?: Maybe<Scalars['Int']>
  q6?: Maybe<Scalars['Int']>
  q7?: Maybe<Scalars['Int']>
  comment?: Maybe<Array<TeamAnketBuildingComment>>
  freeComment?: Maybe<Array<TeamAnketBuildingComment>>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type TeamAnketBuildingComment = {
  userId: Scalars['String']
  target?: Maybe<Scalars['String']>
  targetId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  message: Scalars['String']
  createdAt?: Maybe<Scalars['String']>
}

export type TeamAnketBuildingCommentInput = {
  target?: Maybe<Scalars['String']>
  targetId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  message: Scalars['String']
}

export type TeamAnketBuildingData = {
  anketSummary: TeamAnketBuildingSummary
  anketEntries?: Maybe<Array<TeamAnketBuildingEntry>>
}

export type TeamAnketBuildingDataForExport = {
  teamName: Scalars['String']
  anketDataList?: Maybe<Array<TeamAnketBuildingData>>
}

export type TeamAnketBuildingEntry = {
  id: Scalars['ID']
  userFullName: Scalars['String']
  date?: Maybe<Scalars['String']>
  q1?: Maybe<Scalars['Int']>
  q2?: Maybe<Scalars['Int']>
  q3?: Maybe<Scalars['Int']>
  q4?: Maybe<Scalars['Int']>
  q5?: Maybe<Scalars['Int']>
  q6?: Maybe<Scalars['Int']>
  average?: Maybe<Scalars['Float']>
  freeComment?: Maybe<Array<TeamAnketBuildingComment>>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type TeamAnketBuildingItem = {
  summaryStatus: TeamAnketBuildingSummaryStatus
  summaryCount: Scalars['Int']
  memberCount: Scalars['Int']
  closedSummaryList?: Maybe<Array<TeamAnketBuildingSummary>>
  openSummary?: Maybe<TeamAnketBuildingSummary>
  answerStatus?: Maybe<TeamAnketAnswerStatus>
  unansweredMemberList?: Maybe<Array<TeamMember>>
}

export type TeamAnketBuildingSendEmail = {
  isCompleted: Scalars['Boolean']
  sentCount: Scalars['Int']
  errorMessage?: Maybe<Scalars['String']>
}

export type TeamAnketBuildingStatusForStart = {
  targetAnketId?: Maybe<Scalars['String']>
  isActive: Scalars['Boolean']
  hasAnswered: Scalars['Boolean']
}

export type TeamAnketBuildingSummary = {
  id: Scalars['ID']
  type: AnketType
  teamId: Scalars['ID']
  status: AnketStatus
  date?: Maybe<Scalars['String']>
  average?: Maybe<Scalars['Float']>
  averageMember?: Maybe<Scalars['Float']>
  averageLeader?: Maybe<Scalars['Float']>
  growth?: Maybe<Scalars['Float']>
  building?: Maybe<Scalars['Float']>
  averageQ1?: Maybe<Scalars['Float']>
  averageQ2?: Maybe<Scalars['Float']>
  averageQ3?: Maybe<Scalars['Float']>
  averageQ4?: Maybe<Scalars['Float']>
  averageQ5?: Maybe<Scalars['Float']>
  averageQ6?: Maybe<Scalars['Float']>
  averageQ7?: Maybe<Scalars['Float']>
  total?: Maybe<Scalars['Int']>
  totalQ1?: Maybe<Scalars['Int']>
  totalQ2?: Maybe<Scalars['Int']>
  totalQ3?: Maybe<Scalars['Int']>
  totalQ4?: Maybe<Scalars['Int']>
  totalQ5?: Maybe<Scalars['Int']>
  totalQ6?: Maybe<Scalars['Int']>
  totalQ7?: Maybe<Scalars['Int']>
  leaderTotal?: Maybe<Scalars['Int']>
  leaderQ1?: Maybe<Scalars['Int']>
  leaderQ2?: Maybe<Scalars['Int']>
  leaderQ3?: Maybe<Scalars['Int']>
  leaderQ4?: Maybe<Scalars['Int']>
  leaderQ5?: Maybe<Scalars['Int']>
  leaderQ6?: Maybe<Scalars['Int']>
  leaderQ7?: Maybe<Scalars['Int']>
  memberTotal?: Maybe<Scalars['Int']>
  memberQ1?: Maybe<Scalars['Int']>
  memberQ2?: Maybe<Scalars['Int']>
  memberQ3?: Maybe<Scalars['Int']>
  memberQ4?: Maybe<Scalars['Int']>
  memberQ5?: Maybe<Scalars['Int']>
  memberQ6?: Maybe<Scalars['Int']>
  memberQ7?: Maybe<Scalars['Int']>
  comment?: Maybe<Array<TeamAnketBuildingComment>>
  freeComment?: Maybe<Array<TeamAnketBuildingComment>>
  countAnswer?: Maybe<Scalars['Int']>
  countLeader?: Maybe<Scalars['Int']>
  countMember?: Maybe<Scalars['Int']>
  answerRate?: Maybe<Scalars['Float']>
  anketList?: Maybe<Array<TeamAnketBuilding>>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export enum TeamAnketBuildingSummaryStatus {
  Empty = 'EMPTY',
  Open = 'OPEN',
  Close = 'CLOSE',
}

export enum TeamApprovalCode {
  ExampleCode = 'EXAMPLE_CODE',
  CodeExample = 'CODE_EXAMPLE',
  NewoneCocolabo = 'NEWONE_Cocolabo',
}

export type TeamBuilding = {
  id: Scalars['ID']
  status: TeamBuildingStatus
  q1PresenNumber?: Maybe<Scalars['Int']>
  q1PresenUserIds?: Maybe<Array<Scalars['String']>>
  q1PresenMinute?: Maybe<Scalars['Int']>
  resultQ1?: Maybe<Scalars['String']>
  q2PresenNumber?: Maybe<Scalars['Int']>
  q2PresenUserIds?: Maybe<Array<Scalars['String']>>
  q2PresenMinute?: Maybe<Scalars['Int']>
  resultQ2Pre?: Maybe<Scalars['String']>
  resultQ2?: Maybe<Scalars['String']>
  resultQ3?: Maybe<Scalars['String']>
  resultQ4?: Maybe<Scalars['String']>
  endAt?: Maybe<Scalars['String']>
  anketStartAt?: Maybe<Scalars['String']>
  anketSpan?: Maybe<Scalars['Int']>
  anketLimit?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
  timeCreated?: Maybe<Scalars['String']>
  timeQ1?: Maybe<Scalars['String']>
  timeQ1Select?: Maybe<Scalars['String']>
  timeQ1Presen?: Maybe<Scalars['String']>
  timeQ1End?: Maybe<Scalars['String']>
  timeQ2?: Maybe<Scalars['String']>
  timeQ2Select?: Maybe<Scalars['String']>
  timeQ2Presen?: Maybe<Scalars['String']>
  timeQ2Result?: Maybe<Scalars['String']>
  timeQ2End?: Maybe<Scalars['String']>
  timeQ2Vote02?: Maybe<Scalars['String']>
  timeQ2Result02?: Maybe<Scalars['String']>
  timeQ3LP?: Maybe<Scalars['String']>
  timeQ3?: Maybe<Scalars['String']>
  timeQ3Vote1?: Maybe<Scalars['String']>
  timeQ3Vote2?: Maybe<Scalars['String']>
  timeQ3Adjust?: Maybe<Scalars['String']>
  timeQ3End?: Maybe<Scalars['String']>
  timeQ4?: Maybe<Scalars['String']>
  timeQ4Adjust?: Maybe<Scalars['String']>
  timeQ4End?: Maybe<Scalars['String']>
  timeQ5?: Maybe<Scalars['String']>
  timeQ5Actions?: Maybe<Scalars['String']>
  timeCompleted?: Maybe<Scalars['String']>
}

export type TeamBuildingAction = {
  id: Scalars['ID']
  teamId: Scalars['ID']
  assignedTeamMemberId: Scalars['ID']
  assignedTeamMemberName: Scalars['String']
  name: Scalars['String']
  reason: Scalars['String']
  content: Scalars['String']
  timing: Scalars['String']
  editable: Scalars['Int']
  dummyHash: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type TeamBuildingActionConnection = {
  items: Array<TeamBuildingAction>
  nextToken?: Maybe<Scalars['String']>
}

export type TeamBuildingActionDate = {
  id: Scalars['ID']
  teamId: Scalars['ID']
  teamBuildingActionId: Scalars['ID']
  name: Scalars['String']
  assignedTeamMemberId: Scalars['String']
  assignedTeamMemberName: Scalars['String']
  date: Scalars['String']
  status: TeamBuildingActionDateStatus
  dummyHash: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
  teamBuildingAction?: Maybe<TeamBuildingAction>
}

export type TeamBuildingActionDateConnection = {
  items: Array<TeamBuildingActionDate>
  nextToken?: Maybe<Scalars['String']>
}

export enum TeamBuildingActionDateStatus {
  Created = 'CREATED',
  Completed = 'COMPLETED',
}

export enum TeamBuildingDataTypes {
  TeamBuildingReviewSummary = 'TEAM_BUILDING_REVIEW_SUMMARY',
  TeamBuildingReview = 'TEAM_BUILDING_REVIEW',
  TeamBuildingActionDate = 'TEAM_BUILDING_ACTION_DATE',
  TeamBuildingAction = 'TEAM_BUILDING_ACTION',
  TeamBuildingMember = 'TEAM_BUILDING_MEMBER',
  TeamBuilding = 'TEAM_BUILDING',
  Team = 'TEAM',
}

export type TeamBuildingMember = {
  id: Scalars['ID']
  teamId: Scalars['ID']
  userId: Scalars['ID']
  resultQ1?: Maybe<Scalars['String']>
  resultQ2Pre?: Maybe<Scalars['String']>
  resultQ2?: Maybe<Scalars['String']>
  resultQ3?: Maybe<Scalars['String']>
  resultQ4?: Maybe<Scalars['String']>
  resultQ5?: Maybe<Scalars['String']>
  dummyHash: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
  user?: Maybe<User>
  teamMember?: Maybe<TeamMember>
}

export type TeamBuildingMemberConnection = {
  items: Array<TeamBuildingMember>
  nextToken?: Maybe<Scalars['String']>
}

export type TeamBuildingResetConnection = {
  isTransactionCompleted?: Maybe<Scalars['Boolean']>
  resetInOrderProgress?: Maybe<TeamBuildingResetInOrderProgress>
  error?: Maybe<TeamBuildingResetError>
}

export type TeamBuildingResetError = {
  code: Scalars['String']
  time: Scalars['String']
  requestId: Scalars['String']
  statusCode: Scalars['Int']
  errorMessage: Scalars['String']
}

export type TeamBuildingResetInOrderProgress = {
  deletingData?: Maybe<TeamBuildingDataTypes>
  completedSteps?: Maybe<Array<Maybe<TeamBuildingDataTypes>>>
  notCompletedSteps?: Maybe<Array<Maybe<TeamBuildingDataTypes>>>
  isAllCompleted: Scalars['Boolean']
}

export type TeamBuildingReview = {
  id: Scalars['ID']
  hash: Scalars['String']
  gsi1PK: Scalars['String']
  gsi1SK: Scalars['String']
  date: Scalars['String']
  teamId: Scalars['ID']
  status: TeamBuildingReviewStatus
  iceBreak?: Maybe<Scalars['String']>
  iceBreakNumber?: Maybe<Scalars['Int']>
  iceBreakUserIds?: Maybe<Array<Scalars['String']>>
  iceBreakMinute?: Maybe<Scalars['Int']>
  answers?: Maybe<Array<TeamBuildingReviewTeamAnswer>>
  nextAt?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
  team?: Maybe<Team>
  timeCreated?: Maybe<Scalars['String']>
  timeStep1?: Maybe<Scalars['String']>
  timeStep1Select?: Maybe<Scalars['String']>
  timeStep1Presen?: Maybe<Scalars['String']>
  timeStep1End?: Maybe<Scalars['String']>
  timeStep2?: Maybe<Scalars['String']>
  timeStep2History?: Maybe<Scalars['String']>
  timeStep2Input?: Maybe<Scalars['String']>
  timeStep2Confirm?: Maybe<Scalars['String']>
  timeStep2Select?: Maybe<Scalars['String']>
  timeStep2Presen?: Maybe<Scalars['String']>
  timeStep2End?: Maybe<Scalars['String']>
  timeStep3?: Maybe<Scalars['String']>
  timeStep3Select?: Maybe<Scalars['String']>
  timeCompleted?: Maybe<Scalars['String']>
}

export type TeamBuildingReviewConnection = {
  items: Array<TeamBuildingReview>
  nextToken?: Maybe<Scalars['String']>
}

export type TeamBuildingReviewMember = {
  id: Scalars['ID']
  gsi1PK: Scalars['String']
  gsi1SK: Scalars['String']
  teamBuildingReviewId: Scalars['String']
  hash: Scalars['String']
  date: Scalars['String']
  teamId: Scalars['ID']
  userId: Scalars['ID']
  username: Scalars['String']
  teamMemberId: Scalars['ID']
  answers: Array<TeamBuildingReviewTeamMemberAnswer>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type TeamBuildingReviewMemberAnswerInput = {
  type: TeamBuildingReviewTeamAnswerType
  index: Scalars['Int']
  value: Scalars['Int']
}

export type TeamBuildingReviewMemberConnection = {
  items: Array<TeamBuildingReviewMember>
  nextToken?: Maybe<Scalars['String']>
}

export enum TeamBuildingReviewStatus {
  Created = 'CREATED',
  Step1 = 'STEP1',
  Step1Select = 'STEP1_SELECT',
  Step1Presen = 'STEP1_PRESEN',
  Step1End = 'STEP1_END',
  Step2 = 'STEP2',
  Step2History = 'STEP2_HISTORY',
  Step2Input = 'STEP2_INPUT',
  Step2Confirm = 'STEP2_CONFIRM',
  Step2Select = 'STEP2_SELECT',
  Step2Presen = 'STEP2_PRESEN',
  Step2End = 'STEP2_END',
  Step3 = 'STEP3',
  Step3Select = 'STEP3_SELECT',
  Completed = 'COMPLETED',
}

export type TeamBuildingReviewSummary = {
  id: Scalars['ID']
  histories?: Maybe<Array<TeamBuildingReviewSummaryHistory>>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type TeamBuildingReviewSummaryHistory = {
  date: Scalars['String']
  averages?: Maybe<Array<TeamBuildingReviewSummaryHistoryAverage>>
  createdAt: Scalars['String']
}

export type TeamBuildingReviewSummaryHistoryAverage = {
  type: TeamBuildingReviewTeamAnswerType
  index: Scalars['Int']
  average: Scalars['Float']
}

export type TeamBuildingReviewTeamAnswer = {
  type: TeamBuildingReviewTeamAnswerType
  index: Scalars['Int']
  average: Scalars['Float']
  items: Array<TeamBuildingReviewTeamAnswerItem>
}

export type TeamBuildingReviewTeamAnswerItem = {
  userId: Scalars['ID']
  username: Scalars['String']
  value: Scalars['Int']
}

export enum TeamBuildingReviewTeamAnswerType {
  TeamGoal = 'TEAM_GOAL',
  CodeOfConduct = 'CODE_OF_CONDUCT',
}

export type TeamBuildingReviewTeamMemberAnswer = {
  type: TeamBuildingReviewTeamAnswerType
  index: Scalars['Int']
  value: Scalars['Int']
}

export enum TeamBuildingStatus {
  Created = 'CREATED',
  Q1 = 'Q1',
  Q1Select = 'Q1_SELECT',
  Q1Presen = 'Q1_PRESEN',
  Q1End = 'Q1_END',
  Q2 = 'Q2',
  Q2Select = 'Q2_SELECT',
  Q2Presen = 'Q2_PRESEN',
  Q2Result = 'Q2_RESULT',
  Q2Vote_02 = 'Q2_VOTE_02',
  Q2Result_02 = 'Q2_RESULT_02',
  Q2End = 'Q2_END',
  Q3Lp = 'Q3_LP',
  Q3 = 'Q3',
  Q3Vote1 = 'Q3_VOTE1',
  Q3Vote2 = 'Q3_VOTE2',
  Q3Adjust = 'Q3_ADJUST',
  Q3End = 'Q3_END',
  Q4 = 'Q4',
  Q4Adjust = 'Q4_ADJUST',
  Q4End = 'Q4_END',
  Q5 = 'Q5',
  Q5Actions = 'Q5_ACTIONS',
  Completed = 'COMPLETED',
}

export type TeamBuildingV1ResultQ1 = {
  version: Scalars['String']
  items: Array<TeamBuildingV1ResultQ1Item>
}

export type TeamBuildingV1ResultQ1Item = {
  answer: Scalars['String']
  userId: Scalars['String']
  fullName: Scalars['String']
}

export type TeamBuildingV1ResultQ2 = {
  version: Scalars['String']
  items: Array<TeamBuildingV1ResultQ2Item>
}

export type TeamBuildingV1ResultQ2Item = {
  answer: Scalars['String']
  count: Scalars['Int']
}

export type TeamBuildingV1ResultQ3 = {
  version: Scalars['String']
  selected: Array<ResultQ3RankingItem>
  rankings: Array<ResultQ3RankingItem>
  answer: Scalars['String']
}

export type TeamBuildingV1ResultQ4 = {
  version: Scalars['String']
  rankings: Array<TeamBuildingV1ResultQ4Ranking>
  items: Array<TeamBuildingV1ResultQ4Item>
}

export type TeamBuildingV1ResultQ4Item = {
  id: Scalars['String']
  answer: Scalars['String']
}

export type TeamBuildingV1ResultQ4Ranking = {
  id: Scalars['String']
  answer: Scalars['String']
  count: Scalars['Int']
}

export type TeamConnection = {
  items: Array<Team>
  nextToken?: Maybe<Scalars['String']>
}

export type TeamContract = {
  id: Scalars['ID']
  teamId: Scalars['ID']
  teamPlanId: Scalars['ID']
  startAt: Scalars['String']
  endAt: Scalars['String']
  limit: Scalars['Int']
  price: Scalars['Int']
  discountPrice: Scalars['Int']
  paymentMethod: PaymentMethod
  paymentStatus: PaymentStatus
  dummyHash: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type TeamContractConnection = {
  items: Array<TeamContract>
  nextToken?: Maybe<Scalars['String']>
}

export type TeamFilter = {
  ownerUserId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  ownerEmail?: Maybe<Scalars['String']>
}

export type TeamKarte = {
  id: Scalars['ID']
  data: Scalars['String']
  anket?: Maybe<Scalars['AWSJSON']>
  personal?: Maybe<Scalars['AWSJSON']>
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
  userId: Scalars['ID']
  teamId: Scalars['ID']
}

export type TeamKarteConnection = {
  items: Array<TeamKarte>
  nextToken?: Maybe<Scalars['String']>
}

export type TeamKarteFilter = {
  teamId: Scalars['String']
  userId?: Maybe<Scalars['String']>
}

export type TeamKarteMemberInput = {
  teamId: Scalars['ID']
  data: Scalars['String']
  anket: Scalars['AWSJSON']
  personal: Scalars['AWSJSON']
}

export type TeamMember = {
  id: Scalars['ID']
  teamId: Scalars['ID']
  userId: Scalars['ID']
  role: Array<TeamMemberRole>
  fullName: Scalars['String']
  username?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  email: Scalars['String']
  statusUsage: TeamMemberStatusUsage
  user?: Maybe<User>
  anketCount?: Maybe<Scalars['Int']>
  anketLatestDate?: Maybe<Scalars['String']>
  anketRemainderEmailIds?: Maybe<Array<Scalars['String']>>
  dummyHash: Scalars['String']
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
  teamKarteIds?: Maybe<Array<Scalars['ID']>>
  invitationDateTime?: Maybe<Scalars['String']>
}

export type TeamMemberConnection = {
  items: Array<TeamMember>
  nextToken?: Maybe<Scalars['String']>
}

export type TeamMemberCreationAttributes = {
  email: Scalars['String']
  role: Array<TeamMemberRole>
  fullName: Scalars['String']
  username?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
}

export type TeamMemberFilter = {
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  isReservedInvitation?: Maybe<Scalars['Boolean']>
}

export enum TeamMemberRole {
  Admin = 'ADMIN',
  Leader = 'LEADER',
  Member = 'MEMBER',
}

export enum TeamMemberStatusUsage {
  Invited = 'INVITED',
  Active = 'ACTIVE',
  Stop = 'STOP',
  Reserved = 'RESERVED',
}

export type TeamPlan = {
  id: Scalars['ID']
  sort: Scalars['Int']
  code: Scalars['String']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  businessName: Scalars['String']
  businessDescription?: Maybe<Scalars['String']>
  tools: Array<TeamTool>
  limit: Scalars['Int']
  price: Scalars['Int']
  publicRange: Scalars['Int']
}

export type TeamPlanConnection = {
  items: Array<TeamPlan>
  nextToken?: Maybe<Scalars['String']>
}

export type TeamPlanFilter = {
  publicRange?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
}

export enum TeamStatusAdmin {
  Confirming = 'CONFIRMING',
  Active = 'ACTIVE',
  NonActive = 'NON_ACTIVE',
  Banned = 'BANNED',
}

export enum TeamStatusUsage {
  InUse = 'IN_USE',
  NonUse = 'NON_USE',
}

export type TeamSummaryBuilding = {
  goal?: Maybe<Scalars['String']>
  codeOfConducts?: Maybe<Array<Scalars['String']>>
  progress?: Maybe<Scalars['Int']>
  latestActions?: Maybe<Array<TeamBuildingActionDate>>
}

export enum TeamTool {
  Building = 'BUILDING',
  BuildingCheck = 'BUILDING_CHECK',
  BuildingReview = 'BUILDING_REVIEW',
  Ghost = 'GHOST',
  Karte = 'KARTE',
  Meeting = 'MEETING',
  Onboarding = 'ONBOARDING',
}

export type TeamToolUsageStatus = {
  playedTeamCount: Scalars['Int']
  activeTeamCount: Scalars['Int']
}

export type TransferTeamAdminAcceptInput = {
  id: Scalars['ID']
  transferToken: Scalars['String']
}

export type TransferTeamAdminInput = {
  id: Scalars['ID']
  transferOwnerUserId: Scalars['ID']
}

export type UpdateGhostHistory = {
  id: Scalars['ID']
  ghostId: Scalars['String']
}

export type UpdateGhostTeamInput = {
  id: Scalars['ID']
  discover?: Maybe<Scalars['Int']>
  selectListManage?: Maybe<Scalars['Boolean']>
  report?: Maybe<Scalars['Int']>
  reportManage?: Maybe<Scalars['Boolean']>
  research?: Maybe<Scalars['Int']>
  researchSecond?: Maybe<Scalars['Int']>
  researchManage?: Maybe<Scalars['Boolean']>
  researchSecondManage?: Maybe<Scalars['Boolean']>
  feelingNextActionManage?: Maybe<Scalars['Boolean']>
  like?: Maybe<Scalars['Int']>
  likeSecond?: Maybe<Scalars['Int']>
  likeManage?: Maybe<Scalars['Boolean']>
  likeSecondManage?: Maybe<Scalars['Boolean']>
  status?: Maybe<GhostTeamManage>
  progress?: Maybe<GhostProgress>
  timerS1Started?: Maybe<Scalars['String']>
  timerS1SelectMinute?: Maybe<Scalars['Int']>
  timerS1SelectNumber?: Maybe<Scalars['Int']>
  timerS1SelectUserIds?: Maybe<Array<Scalars['String']>>
  timerS1SelectStarted?: Maybe<Scalars['String']>
  timerS2Started?: Maybe<Scalars['String']>
  timerS2SelectMinute?: Maybe<Scalars['Int']>
  timerS2SelectNumber?: Maybe<Scalars['Int']>
  timerS2SelectUserIds?: Maybe<Array<Scalars['String']>>
  timerS2SelectStarted?: Maybe<Scalars['String']>
  timerS3Started?: Maybe<Scalars['String']>
  timerS3SelectMinuteReason?: Maybe<Scalars['Int']>
  timerS3SelectNumberReason?: Maybe<Scalars['Int']>
  timerS3SelectUserIdsReason?: Maybe<Array<Scalars['String']>>
  timerS3SelectStartedReason?: Maybe<Scalars['String']>
  timerS3Started2?: Maybe<Scalars['String']>
  timerS3SelectMinute?: Maybe<Scalars['Int']>
  timerS3SelectNumber?: Maybe<Scalars['Int']>
  timerS3SelectUserIds?: Maybe<Array<Scalars['String']>>
  timerS3SelectStarted?: Maybe<Scalars['String']>
  timerS4Started?: Maybe<Scalars['String']>
  timerS4SelectMinute?: Maybe<Scalars['Int']>
  timerS4SelectNumber?: Maybe<Scalars['Int']>
  timerS4SelectUserIds?: Maybe<Array<Scalars['String']>>
  timerS4SelectStarted?: Maybe<Scalars['String']>
}

export type UpdateInvoiceDetailTeamInput = {
  id: Scalars['ID']
  cost?: Maybe<Scalars['Int']>
  discount?: Maybe<Scalars['Int']>
  plans?: Maybe<Scalars['String']>
}

export type UpdateInvoiceInput = {
  id: Scalars['ID']
  amount?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Int']>
  discount?: Maybe<Scalars['Int']>
  status?: Maybe<InvoiceStatus>
}

export type UpdateMasterInput = {
  id: Scalars['ID']
  type?: Maybe<MasterType>
  sort?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  detail?: Maybe<Scalars['String']>
  timing?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  businessDescription?: Maybe<Scalars['String']>
  tools?: Maybe<Array<TeamTool>>
  limit?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['Int']>
  publicRange?: Maybe<Scalars['Int']>
  parentType?: Maybe<MasterType>
  parentId?: Maybe<Scalars['String']>
}

export type UpdateTeamAdminInput = {
  statusAdmin?: Maybe<TeamStatusAdmin>
  username?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  division?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  companyIconUrl?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  companyZipCode?: Maybe<Scalars['String']>
  companyPrefecture?: Maybe<Scalars['String']>
  companyAddress1?: Maybe<Scalars['String']>
  companyAddress2?: Maybe<Scalars['String']>
  companyPhone?: Maybe<Scalars['String']>
  companyNumberOfEmployees?: Maybe<Scalars['String']>
  companyManagerName?: Maybe<Scalars['String']>
  contractAt?: Maybe<Scalars['String']>
  onboardingContractAt?: Maybe<Scalars['String']>
  billingCode?: Maybe<Scalars['String']>
}

export type UpdateTeamBuildingActionDateInput = {
  id: Scalars['ID']
  date?: Maybe<Scalars['String']>
  status?: Maybe<TeamBuildingActionDateStatus>
}

export type UpdateTeamBuildingActionInput = {
  id: Scalars['ID']
  assignedTeamMemberId?: Maybe<Scalars['ID']>
  assignedTeamMemberName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  timing?: Maybe<Scalars['String']>
  editable?: Maybe<Scalars['Int']>
}

export type UpdateTeamBuildingInput = {
  id: Scalars['ID']
  status?: Maybe<TeamBuildingStatus>
  endAt?: Maybe<Scalars['String']>
  q1PresenNumber?: Maybe<Scalars['Int']>
  q1PresenUserIds?: Maybe<Array<Scalars['String']>>
  q1PresenMinute?: Maybe<Scalars['Int']>
  resultQ1?: Maybe<Scalars['String']>
  q2PresenNumber?: Maybe<Scalars['Int']>
  q2PresenUserIds?: Maybe<Array<Scalars['String']>>
  q2PresenMinute?: Maybe<Scalars['Int']>
  resultQ2Pre?: Maybe<Scalars['String']>
  resultQ2?: Maybe<Scalars['String']>
  resultQ3?: Maybe<Scalars['String']>
  resultQ4?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  timeCreated?: Maybe<Scalars['String']>
  timeQ1?: Maybe<Scalars['String']>
  timeQ1Select?: Maybe<Scalars['String']>
  timeQ1Presen?: Maybe<Scalars['String']>
  timeQ1End?: Maybe<Scalars['String']>
  timeQ2?: Maybe<Scalars['String']>
  timeQ2Select?: Maybe<Scalars['String']>
  timeQ2Presen?: Maybe<Scalars['String']>
  timeQ2Result?: Maybe<Scalars['String']>
  timeQ2End?: Maybe<Scalars['String']>
  timeQ2Vote02?: Maybe<Scalars['String']>
  timeQ2Result02?: Maybe<Scalars['String']>
  timeQ3LP?: Maybe<Scalars['String']>
  timeQ3?: Maybe<Scalars['String']>
  timeQ3Vote1?: Maybe<Scalars['String']>
  timeQ3Vote2?: Maybe<Scalars['String']>
  timeQ3Adjust?: Maybe<Scalars['String']>
  timeQ3End?: Maybe<Scalars['String']>
  timeQ4?: Maybe<Scalars['String']>
  timeQ4Adjust?: Maybe<Scalars['String']>
  timeQ4End?: Maybe<Scalars['String']>
  timeQ5?: Maybe<Scalars['String']>
  timeQ5Actions?: Maybe<Scalars['String']>
  timeCompleted?: Maybe<Scalars['String']>
  anketStartAt?: Maybe<Scalars['String']>
  anketSpan?: Maybe<Scalars['Int']>
  anketLimit?: Maybe<Scalars['Int']>
}

export type UpdateTeamBuildingMemberInput = {
  teamId: Scalars['ID']
  resultQ1?: Maybe<Scalars['String']>
  resultQ2Pre?: Maybe<Scalars['String']>
  resultQ2?: Maybe<Scalars['String']>
  resultQ3?: Maybe<Scalars['String']>
  resultQ4?: Maybe<Scalars['String']>
  resultQ5?: Maybe<Scalars['String']>
}

export type UpdateTeamBuildingReviewInput = {
  id: Scalars['ID']
  status?: Maybe<TeamBuildingReviewStatus>
  iceBreak?: Maybe<Scalars['String']>
  iceBreakNumber?: Maybe<Scalars['Int']>
  iceBreakUserIds?: Maybe<Array<Scalars['String']>>
  iceBreakMinute?: Maybe<Scalars['Int']>
  nextAt?: Maybe<Scalars['String']>
}

export type UpdateTeamBuildingReviewMemberInput = {
  teamBuildingReviewId: Scalars['ID']
  answers?: Maybe<Array<TeamBuildingReviewMemberAnswerInput>>
}

export type UpdateTeamContractInput = {
  startAt?: Maybe<Scalars['String']>
  endAt?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['Int']>
  discountPrice?: Maybe<Scalars['Int']>
  paymentStatus?: Maybe<PaymentStatus>
}

export type UpdateTeamInput = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  statusUsage?: Maybe<TeamStatusUsage>
  teamTools?: Maybe<Array<TeamTool>>
  statusViewTbCheckRespondents?: Maybe<Permissions>
}

export type UpdateTeamMemberInput = {
  id: Scalars['ID']
  role?: Maybe<Array<TeamMemberRole>>
  fullName?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  organization?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  invitationDateTime?: Maybe<Scalars['String']>
}

export type UpdateTeamMembersInput = {
  teamId: Scalars['ID']
  members: Array<UpdateTeamMemberInput>
}

export type UpdateTeamPlanInput = {
  teamId: Scalars['ID']
  planId: Scalars['ID']
  planStatus: RelatedTeamPlanStatus
}

export type User = {
  id: Scalars['ID']
  email: Scalars['String']
  nickname?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  shortDescription?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  setupStartedAt?: Maybe<Scalars['String']>
  setupStartedAtList?: Maybe<Array<Maybe<Scalars['String']>>>
  karuteStartedAt?: Maybe<Scalars['String']>
  emailNotifySetup?: Maybe<Scalars['Int']>
  emailNotifyHour?: Maybe<Scalars['Int']>
  emailNotifyMinute?: Maybe<Scalars['Int']>
  isDeleted?: Maybe<Scalars['Int']>
  q1?: Maybe<Scalars['String']>
  q2?: Maybe<Scalars['String']>
  q3?: Maybe<Scalars['String']>
  q4?: Maybe<Scalars['String']>
  q5?: Maybe<Scalars['String']>
  anketDate?: Maybe<Scalars['String']>
  accountType?: Maybe<AccountType>
  onboardingUsageStatus?: Maybe<OnboardingUsageStatus>
  statusAdmin?: Maybe<TeamStatusAdmin>
  username?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  division?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
  companyIconUrl?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  companyZipCode?: Maybe<Scalars['String']>
  companyPrefecture?: Maybe<Scalars['String']>
  companyAddress1?: Maybe<Scalars['String']>
  companyAddress2?: Maybe<Scalars['String']>
  companyPhone?: Maybe<Scalars['String']>
  companyNumberOfEmployees?: Maybe<Scalars['String']>
  companyManagerName?: Maybe<Scalars['String']>
  contractAt?: Maybe<Scalars['String']>
  onboardingContractAt?: Maybe<Scalars['String']>
  billingCode?: Maybe<Scalars['String']>
}

export type UserConnection = {
  items: Array<User>
  nextToken?: Maybe<Scalars['String']>
}

export type UserFilter = {
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type UserPrepareAccountInput = {
  id: Scalars['ID']
  email: Scalars['String']
  accountType: AccountType
}

export type UserUpdateInput = {
  nickname?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  shortDescription?: Maybe<Scalars['String']>
  setupStartedAt?: Maybe<Scalars['String']>
  setupStartedAtList?: Maybe<Array<Maybe<Scalars['String']>>>
  description?: Maybe<Scalars['String']>
  emailNotifySetup?: Maybe<Scalars['Int']>
  emailNotifyHour?: Maybe<Scalars['Int']>
  emailNotifyMinute?: Maybe<Scalars['Int']>
  isDeleted?: Maybe<Scalars['Int']>
  q1?: Maybe<Scalars['String']>
  q2?: Maybe<Scalars['String']>
  q3?: Maybe<Scalars['String']>
  q4?: Maybe<Scalars['String']>
  q5?: Maybe<Scalars['String']>
  anketDate?: Maybe<Scalars['String']>
}

export type VoteGhostReportsInput = {
  reports: Array<GhostReportInput>
  upOrDown: Array<Scalars['Boolean']>
}

export type VoteReportsResult = {
  teamId: Scalars['String']
  historyWithGhost?: Maybe<GhostHistory>
}
