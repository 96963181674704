import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import { useCustomMediaQuery } from 'hooks/mediaQuery'

export const ClientLogoBox: React.FC = () => {
  const classes = useStyles({})

  const isSmDown = useCustomMediaQuery('down', 'sm')

  const amountOfLogo = Array.from({ length: 8 }, (_, i) => i + 1) //  set amount of client logo to length props (ex : length:3)

  return (
    <div className={classes.root}>
      <div className={classes.logoBoxHeader}>協賛企業・取引先・参加企業一覧</div>
      <div className={classes.logosWrapper}>
        <div className={classes.logoImageBox}>
          {amountOfLogo.map((index) => (
            <img
              key={`/img/topPage/clientLogo/logo_${index}`}
              src={process.env.PUBLIC_URL + '/img/topPage/clientLogo/logo_' + index + '.png'}
              alt="clientLogo"
              className={isSmDown ? classes.imgSP : classes.imgPC}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '63px 16px 60px',
      [theme.breakpoints.up('md')]: {
        gap: 30,
        padding: '80px 16px',
      },
    },
    logoBoxHeader: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: 28,
      fontSize: 14,
      fontWeight: 'bold',
    },
    logosWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    logoImageBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 17,
      [theme.breakpoints.up('md')]: {
        width: 900, // ロゴの数に応じて適切な行数になるよう設定
      },
    },
    imgPC: {
      display: 'block',
      height: 58,
    },
    imgSP: {
      display: 'block',
      height: 32,
    },
  }),
  { name: 'ClientLogoBox' }
)
