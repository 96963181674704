import * as constants from './constants'
import * as pages from './pages'

//----------------------------------
// デフォルトのメタデータ
const APP_NAME = 'Cocolabo（ココラボ）'
const titleTemplate = (title: string) => `${title} | ${APP_NAME}`

const DEFAULT_DESCRIPTION = `${APP_NAME}は、多数の業務を抱える管理職の方々の負荷を軽減し楽しんでマネジメントできるようにすることをコンセプトとして生まれたサービスです。課題に合わせてツールを開発していきますので目的に合わせてご利用ください`

export const DEFAULT_META = {
  title: `${APP_NAME} | 高エンゲージメントチーム作り支援ツール`,
  description: DEFAULT_DESCRIPTION,
} as const
//----------------------------------

// ! APP_METAS 内で、現在の URL の pathname が最初にマッチする route の値に基づいて、
// ! 対応するメタデータ（title や description）が head 要素に適用されます。
// ! マッチングは配列の先頭から行われるため、定義の順番に注意してください。
export const APP_METAS = [
  {
    route: constants.PAGE_SIGNIN,
    title: titleTemplate('ログイン'),
    description: DEFAULT_DESCRIPTION,
  },
  {
    route: constants.PAGE_SIGNUP,
    title: titleTemplate('新規登録'),
    description: DEFAULT_DESCRIPTION,
  },
  {
    route: constants.PAGE_RESTORE,
    title: titleTemplate('パスワード再設定'),
    description: DEFAULT_DESCRIPTION,
  },
  {
    route: constants.PAGE_RESET_PASSWORD,
    title: titleTemplate('パスワード再設定'),
    description: DEFAULT_DESCRIPTION,
  },
  {
    route: constants.PAGE_ABOUT,
    title: titleTemplate('運営会社'),
    description: `${APP_NAME}の運営会社「NEWONE」の紹介ページです。「組織が個人を選ぶ」から「個人が組織を選ぶ」に。そして、組織と個人が対等な関係に。そのような「あたらしい、個人と組織の関係」を作るHR領域の支援サービスを提供する企業です。`,
  },
  {
    route: constants.PAGE_TERMS,
    title: titleTemplate('利用規約'),
    description: `${APP_NAME}の利用規約ページです。`,
  },
  {
    route: constants.PAGE_PRIVACY_POLICY,
    title: titleTemplate('プライバシーポリシー'),
    description: `${APP_NAME}のプライバシーポリシーページです。`,
  },
  {
    route: constants.PAGE_FEATURES,
    title: titleTemplate('機能一覧'),
    description: `${APP_NAME}は、昨今の時流を掴み、常に最新・最先端なエンゲージメント向上のための手法を機能を通じてご利用いただけます。`,
  },
  {
    route: constants.PAGE_PLAN,
    title: titleTemplate('料金プラン'),
    description: `${APP_NAME}は、チームでのご利用の場合は月額料金でオンボーディングプランとチームビルディングプランの2種類の料金がございます。単体でのご利用の場合は無料プランがございます。`,
  },
  {
    route: constants.PAGE_CASE,
    title: titleTemplate('導入事例'),
    description: `${APP_NAME}は、チームエンゲージメントを増やすためのSaaSツール群です。初めてエンゲージメント向上施策にお取り組みされる方でも、誰でも簡単にすぐ始められ絶対に使いこなせるツールです。`,
  },
  {
    route: constants.PAGE_RESOURCES,
    title: titleTemplate('お役立ち資料'),
    description: `${APP_NAME}では、エンゲージメント向上に役立つ資料を無料でダウンロードいただけます。エンゲージメント向上研修を牽引してきた実績をもとに各種資料をご用意しております。`,
  },
  {
    route: constants.PAGE_HELP,
    title: titleTemplate('ヘルプ'),
    description: `${APP_NAME}の使い方・お困り事項にお答えします。こちらで解決しない場合は、お問い合わせページより直接お問い合わせ下さい。`,
  },
  {
    route: constants.PAGE_NOTIFICATION,
    title: titleTemplate('お知らせ'),
    description: DEFAULT_DESCRIPTION,
  },
  {
    route: constants.PAGE_FREETRIAL,
    title: titleTemplate('無料トライアル'),
    description: `${APP_NAME}では、エンゲージメント向上に役立つ資料を無料でダウンロードいただけます。エンゲージメント向上研修を牽引してきた実績をもとに各種資料をご用意しております。`,
  },
  {
    route: pages.WITHDRAW,
    title: titleTemplate('退会'),
    description: DEFAULT_DESCRIPTION,
  },

  //----------------------------------
  // セットアップページ
  //----------------------------------
  {
    route: pages.PAGE_SETUP_HOME,
    title: titleTemplate('[ セットアップ ] ホーム'),
    description:
      '「セットアップ」のホームです。セットアップは、初期のチーム作りを支援するツールです。特に新任のマネジャーが人・組織面で何を行うべきかを、最適なタイミングでアドバイスします。',
  },
  {
    route: pages.PAGE_SETUP_LIST,
    title: titleTemplate('[ セットアップ ] アクションリスト'),
    description:
      '「セットアップ」のアクションリストです。チーム開始日（着任日）から起算して様々なアクションが課されます。クリアするごとに成長し、成長に合わせて難易度も上がっていくように設計されています。',
  },
  {
    route: pages.PAGE_SETUP_LANDING,
    title: titleTemplate('[ セットアップ ] 紹介'),
    description:
      '「セットアップ」の紹介ページです。セットアップは、初期のチーム作りを支援するツールです。特に新任のマネジャーが人・組織面で何を行うべきかを、最適なタイミングでアドバイスします。',
  },

  //----------------------------------
  // カルテページ
  //----------------------------------
  {
    route: pages.KARTE_LEADER_HOME,
    title: titleTemplate('[ カルテ ] ホーム'),
    description:
      '「カルテ」のホームです。カルテは、世の中の1on1をアップグレードします。カルテを活用して1on1を行うだけで効率も生産性も爆発的に向上します。',
  },
  {
    route: pages.KARTE_LEADER_PREPARE,
    title: titleTemplate('[ カルテ ] リーダー'),
    description:
      '「カルテ」のリーダー診断ページです。メンバーのカルテNo.をセットして質問に回答すると、そのメンバーに対しての自身の理解度がわかるだけでなく、1on1を行う上での注意事項なども見えてきます。',
  },
  {
    route: pages.KARTE_LEADER_SEARCH,
    title: titleTemplate('[ カルテ ] リーダー分析室'),
    description:
      '「カルテ」のリーダー診断ページです。メンバーのカルテNo.をセットして質問に回答すると、そのメンバーに対しての自身の理解度がわかるだけでなく、1on1を行う上での注意事項なども見えてきます。',
  },
  {
    // pages.KARTE_LEADER_RESULTS, ('/karte/leader/questionnaire/:id/results')
    route: /^\/karte\/leader\/questionnaire\/([a-zA-Z0-9\-]+)\/results$/,
    title: titleTemplate('[ カルテ ] リーダー分析結果'),
    description:
      '「カルテ」のリーダー診断ページです。メンバーのカルテNo.をセットして質問に回答すると、そのメンバーに対しての自身の理解度がわかるだけでなく、1on1を行う上での注意事項なども見えてきます。',
  },
  {
    // pages.KARTE_LEADER_QUESTIONNAIRE, ('/karte/leader/questionnaire/:id')
    route: /^\/karte\/leader\/questionnaire\/([a-zA-Z0-9\-]+)$/,
    title: titleTemplate('[ カルテ ] リーダー分析'),
    description:
      '「カルテ」のリーダー診断ページです。メンバーのカルテNo.をセットして質問に回答すると、そのメンバーに対しての自身の理解度がわかるだけでなく、1on1を行う上での注意事項なども見えてきます。',
  },
  {
    // pages.KARTE_MEMBER_RESULTS ('/karte/leader/questionnaire/:id')
    route: /^\/karte\/member\/([a-zA-Z0-9\-]+)\/questionnaire\/([a-zA-Z0-9\-]+)\/results$/,
    title: titleTemplate('[ カルテ ] メンバー分析結果'),
    description:
      '「カルテ」のメンバー診断ページです。質問に回答するだけでメンバーの仕事の取り組み姿勢、得意なこと苦手なことが見えてきます。',
  },
  {
    // pages.KARTE_MEMBER_QUESTIONNAIRE ('/karte/member/:leaderId/questionnaire/:id')
    route: /^\/karte\/member\/([a-zA-Z0-9\-]+)\/questionnaire\/([a-zA-Z0-9\-]+)$/,
    title: titleTemplate('[ カルテ ] メンバー分析'),
    description:
      '「カルテ」のメンバー診断ページです。質問に回答するだけでメンバーの仕事の取り組み姿勢、得意なこと苦手なことが見えてきます。',
  },
  {
    // pages.KARTE_MEMBER ('/karte/member/:leaderId')
    route: /^\/karte\/member\/([a-zA-Z0-9\-]+)$/,
    title: titleTemplate('[ カルテ ] メンバー'),
    description:
      '「カルテ」のメンバー診断ページです。質問に回答するだけでメンバーの仕事の取り組み姿勢、得意なこと苦手なことが見えてきます。',
  },
  {
    route: pages.KARTE_LANDING,
    title: titleTemplate('[ カルテ ] 紹介'),
    description:
      '「カルテ」の紹介ページです。カルテは、世の中の1on1をアップグレードします。カルテを活用して1on1を行うだけで効率も生産性も爆発的に向上します。',
  },

  //----------------------------------
  // ヒントページ
  //----------------------------------
  {
    route: pages.HINT_TIPS_HOME,
    title: titleTemplate('[ ヒント ] Tips'),
    description:
      '「ヒント」のTips一覧です。ヒントは、管理職の悩みを解消するツールです。様々なシーンで役立つ管理職向けTipsをまとめています。',
  },
  {
    route: pages.HINT_FAQ_HOME,
    title: titleTemplate('[ ヒント ] お悩み相談掲示板'),
    description:
      '「ヒント」のお悩み相談掲示板です。ヒントは、管理職の悩みを解消するツールです。あなたの悩みを相談してみましょう。',
  },
  {
    route: pages.HINT,
    title: titleTemplate('[ ヒント ] Tips'),
    description:
      '「ヒント」のTips一覧です。ヒントは、管理職の悩みを解消するツールです。様々なシーンで役立つ管理職向けTipsをまとめています。',
  },

  //----------------------------------
  // キックオフ
  //----------------------------------
  {
    route: '/tools/building/',
    title: titleTemplate('キックオフ'),
    description: DEFAULT_DESCRIPTION,
  },

  //----------------------------------
  // リフレクション
  //----------------------------------
  {
    route: '/review',
    title: titleTemplate('リフレクション'),
    description: DEFAULT_DESCRIPTION,
  },

  //----------------------------------
  // オバケ
  //----------------------------------
  {
    route: '/ghost/',
    title: titleTemplate('オバケ'),
    description: DEFAULT_DESCRIPTION,
  },

  //----------------------------------
  // チーム版カルテ
  //----------------------------------
  {
    route: '/tools/karte',
    title: titleTemplate('カルテ'),
    description: DEFAULT_DESCRIPTION,
  },

  //----------------------------------
  // オンボーディング
  //----------------------------------
  {
    route: '/onboarding/',
    title: titleTemplate('オンボーディング'),
    description: DEFAULT_DESCRIPTION,
  },

  //----------------------------------
  // TBチェック
  //----------------------------------
  {
    // pages.TeamsAnketNew ('/teams/:teamId/anket/:anketId')
    route: /^\/teams\/([a-zA-Z0-9\-]+)\/anket\/([a-zA-Z0-9\-]+)$/,
    title: titleTemplate('TBチェック'),
    description: DEFAULT_DESCRIPTION,
  },

  //----------------------------------
  // チームビルディング
  //----------------------------------
  {
    route: '/teams/',
    title: titleTemplate('チームビルディング'),
    description: DEFAULT_DESCRIPTION,
  },

  //----------------------------------
  // はたらく価値観診断
  //----------------------------------
  {
    route: pages.Pages.Hataraku,
    title: titleTemplate('はたらく価値観診断'),
    description:
      '簡単なアンケートに答えていくだけで、あなたが働くときに大事にしている「はたらく価値観」を見つけることができる診断サービスです。',
  },
] as const
