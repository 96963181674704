import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'

import { BackCard } from './components/BackCard'
import { HubSpotForm, BREAKPOINT } from './components/HubSpotForm'

import { downloadFiles } from './assets/documents.data'
import * as constants from 'assets/constants'

type Props = {}

export const DownloadPage: React.FC<Props> = (props) => {
  const classes = useStyles()
  const location = useLocation()
  const formId = downloadFiles.filter((item) => item.link === location.pathname.split('/').slice(-1)[0])[0]
    .hubSpotFormId

  const isBreakpointsDown = useMediaQuery(useTheme().breakpoints.down(BREAKPOINT + 48))

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.downloadRoot}>
      <div className={classes.topContainer}>
        <h1>資料ダウンロード</h1>
        <p>
          下記フォームに必要事項を入力し、
          <br />
          「ダウンロード」ボタンを押してください。
        </p>
      </div>

      <BackCard
        style={
          isBreakpointsDown
            ? { margin: '64px 24px 0', padding: '0 0 60px', maxWidth: BREAKPOINT }
            : { margin: '100px auto 0', padding: '0 0 100px', maxWidth: BREAKPOINT }
        }
      >
        <HubSpotForm formId={formId} />
      </BackCard>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  downloadRoot: {
    backgroundColor: constants.COLOR_WHITE,
    padding: '0 0 140px',
    fontFamily: constants.FONT_FAMILY_TOP_TEXT,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h5': {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'normal',
      margin: '82px 0 0',
      fontFamily: constants.FONT_FAMILY_TOP_TITLE,
      [theme.breakpoints.down(BREAKPOINT)]: {
        margin: '26px 0 0',
      },
    },
    '& h1': {
      fontSize: 32,
      fontWeight: 'bold',
      margin: '25px 0 0',
      [theme.breakpoints.down(BREAKPOINT)]: {
        fontSize: 20,
      },
    },
    '& p': {
      margin: '30px 0 0',
      textAlign: 'center',
      lineHeight: 1.8,
      fontWeight: 300,
      [theme.breakpoints.down(BREAKPOINT)]: {
        fontSize: 13,
        margin: '20px 0 0',
      },
    },
  },
}))
